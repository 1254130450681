import { makeStyles } from '@material-ui/core/styles';

const Style = makeStyles((theme) => {

    return ({
        background: {
            width: '100%',
            minHeight: 240,
            textAlign: 'center',
            backgroundColor: `rgba(${theme.colors.primary}, 1)`,
        },
        backgroundContent: {
            position: 'relative',
            maxWidth: theme.sizes.pageWidth,
            marginLeft: 'auto',
            marginRight: 'auto',
            height: '100%',
            width: '100%',
            textAlign: 'left',
        },
        pageTitle: {
            width: '100%',
            marginLeft: theme.sizes.generalPadding,
            fontFamily: theme.fonts.primary.family,
            fontWeight: theme.fonts.primary.weight,
            color: 'white',
            fontSize: 40,
            paddingTop: 40,
        },
        filterBox: {
            width: `calc(100% - ${2*theme.sizes.generalPadding}px)`,
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: 25
        },
        filterDropdownArrow: {
            position: 'absolute',
            top: 5,
            right: 10,
            fontSize: 40
        },
        filterLabel: {
            fontFamily: theme.fonts.primary.family,
            fontWeight: theme.fonts.primary.weight,
            color: 'white',
            fontSize: 26,
        },
        filterDropdownBox: {
            marginTop: 10,
            marginBottom: 30,
            [theme.breakpoints.up("sm")]: {
                paddingRight: 32,
                marginBottom: 0,
            },
        },
        filterDropdowLast : {
            marginTop: 10,
            marginBottom: 30,
            [theme.breakpoints.up("sm")]: {
                marginBottom: 0,
            },
        },
        datePickerCustomInput: {
            width: 'calc(100% - 15px)',
            height: 48,
            borderRadius: 2,
            border: 0,
            cursor: 'pointer',
            fontSize: 22,
            fontFamily: 'TradeGothic',
            fontWeight: 700,
            textTransform: 'capitalize',
            paddingLeft: '15px',
            outline: 'none',
            color: '#333'
        },
        filterDatepickerWrapper: {
            width: '100%'
        },
        boxInfo:{
            width: '100%',
            maxWidth: theme.sizes.pageWidth,
            marginLeft: 'auto',
            marginRight: 'auto',
            paddingTop: 40,
            fontFamily: theme.fonts.primary.family,
            fontWeight: 100,
            marginBottom: 40
        },
        boxInfoContent: {
            width: `calc(100% - ${2*theme.sizes.generalPadding}px)`,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
        boxInfoGrid: {
            marginBottom: 40
        },
        boxInfoText: {
            fontSize: 22,
            lineHeight: '32px',
            paddingRight: 0,
            [theme.breakpoints.up("sm")]: {
                padding: 32,
            },
        },
        itemsTitle: {
            fontFamily: theme.fonts.primary.family,
            fontWeight: theme.fonts.primary.weight,
            fontSize: 32,
            paddingTop: 20,
            display: 'flex',
            alignItems: 'center',
        },
        filterItemToRemove: {
            color: 'white',
            fontFamily: theme.fonts.primary.family,
            fontWeight: 500,
            fontSize: 18,
            backgroundColor: `rgba(${theme.colors.secondary}, 1)`,
            padding: 5,
            borderRadius: 3,
            paddingTop: 10,
            marginRight: 10,
            marginLeft: 15,
            display: 'flex',
            alignItems: 'center',
        },
        filterItemToRemoveCloseIcon: {
            marginTop: -5,
            fontSize: 22,
            marginLeft: 10,
            marginRight: 5,
            cursor: 'pointer'
        },
        itemsTitleNext: {
            fontFamily: theme.fonts.primary.family,
            fontWeight: theme.fonts.primary.weight,
            fontSize: 32,
            paddingTop: 50,
        },
        itemsTitleToday: {
            fontFamily: theme.fonts.primary.family,
            fontWeight: theme.fonts.primary.weight,
            fontSize: 32,
            paddingTop: 50,
            marginBottom: 30,
            textTransform: 'capitalize'
        },
        capitalize: {
            textTransform: 'capitalize'
        },
        itemsTitleLine: {
            width: '100%',
            backgroundColor: '#c8c8c8',
            border: 0,
            height: 1,
            marginTop: 30,
        },
        item: {
            marginTop: 25,
            cursor: 'pointer',
            opacity: 0.8,
            '&:hover': {
                opacity: 1,
            },
        },
        itemContent: {
            paddingTop: 15,
            paddingBottom: 15,
            borderBottom: "1px solid #000",
            [theme.breakpoints.up("sm")]: {
                padding: '0 30px 30px 30px',
            },
        },
        postType: {
            fontFamily: theme.fonts.primary.family,
            fontWeight: theme.fonts.primary.weight,
            fontSize: 20,
            color: `rgba(${theme.colors.primary}, 1)`,
        },
        postTitle: {
            fontFamily: theme.fonts.primary.family,
            fontWeight: theme.fonts.primary.weight,
            fontSize: 30,
            paddingTop: 5,
            paddingBottom: 15,
            [theme.breakpoints.up("sm")]: {
                paddingTop: 8,
                paddingBottom: 24,
            },
            color: '#000000',
        },
        postTag: {
            fontFamily: theme.fonts.primary.family,
            fontWeight: 500,
            fontSize: 14,
            color: 'white',
            backgroundColor: `rgba(${theme.colors.secondary}, 1)`,
            padding: 5,
            textAlign: 'center',
            verticalAlign: 'center',
            borderRadius: 3,
            paddingTop: 8,
            marginRight: 8
        },
        seeMoreButton: {
            width: '100%',
            marginTop: 80,
            border: 0,
            backgroundColor: 'transparent',
            borderBottom: `3px solid rgba(${theme.colors.primary}, 1)`,
            paddingBottom: 15,
            fontFamily: theme.fonts.primary.family,
            fontWeight: theme.fonts.primary.weight,
            fontSize: 25,
            color: `rgba(${theme.colors.primary}, 1)`,
            outline: 'none',
            cursor: 'pointer',
            opacity: 0.6,
            '&:hover': {
                opacity: 1,
            },
            marginBottom: 60
        }
        
    })
});

export default Style;