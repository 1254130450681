import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './components/app';
import reportWebVitals from './reportWebVitals';
import 'fontsource-roboto';
import Theme from './packages/theme';
import { ThemeProvider } from '@material-ui/styles';
import moment from 'moment';
import 'moment/locale/pt-br';

moment.locale(['pt-br', 'pr-br'])

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={Theme}>
      <App />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
