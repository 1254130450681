import { createMuiTheme } from '@material-ui/core/styles';
import { colors, fonts, sizes } from './constants';

function createTheme(options) {
  return createMuiTheme({
    colors: colors,
    fonts: fonts,
    sizes: sizes,
    ...options,
  })
}

const MaterialUiTheme = createMuiTheme(
  {
    palette: {},
    breakpoints: {
      values: {
        xs: 0,
        sm: 800,
        md: 960,
        lg: 1280,
        xl: 1920,
      },
    }
  },
);

export default createTheme(MaterialUiTheme);
  