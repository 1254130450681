import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import { SearchIcon } from '../../packages/icons';
import { List, Box, Hidden, ListItemText } from '@material-ui/core';
import Style from './header-style';
import MenuMobile from './components/menu-mobile';
import InstituitionMap from './components/institution-map';
import SearchArea from './components/search-area';
import { useState, useRef, useEffect } from 'react';
import clickOutside from '../../utils/clickOutside';
import { Link } from 'react-router-dom';
import { ListItemIcon } from '@material-ui/core';
import Loading from '../../components/loading';
import { useLocation } from 'react-router-dom'

export default function Header({ loading }) {
    
    const ref = useRef();
    const classes = Style();
    const [menuMobileEnabled, setMenuMobileEnabled] = useState(false);
    const [mapOpened, setMapOpened] = useState(false);
    const [searchAreaOpened, setSearchAreaOpened] = useState(false);
    const location = useLocation();

    useEffect(() => {
        const handleScroll = (e) => {
            const element = document.getElementById("page-header");
            if(window.scrollY > 60) {
                element.classList.add("active-header");
            } else {
                element.classList.remove("active-header");
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);


    const openMenuMobile = () => {

        setMenuMobileEnabled(true);

        const body = document.getElementsByTagName("body");
        body[0].classList.add("no-overflow");
    }

    const closeMenuMobile = () => {
        setMenuMobileEnabled(false);

        const body = document.getElementsByTagName("body");
        body[0].classList.remove("no-overflow");
    }

    const toggleMap = () => {
        if(mapOpened){
            setMapOpened(false);
        } else {
            setMapOpened(true);
            setSearchAreaOpened(false);
        }
    }

    const toggleSearchArea = () => {
        if(searchAreaOpened){
            setSearchAreaOpened(false);
        } else {
            setSearchAreaOpened(true);
            setMapOpened(false);
        }
    }

    const closeAll = () => {
        setMapOpened(false);
        setSearchAreaOpened(false);
    }

    clickOutside(ref, () => closeAll());

    return (
        <Box className={classes.headerContainer} id="page-header" ref={ref}>
            <header className={classes.headerRoot}>
                <Box className={classes.toolbar}>
                    <Box className={classes.logoBox}>
                        <div className={classes.logoText} onClick={() => {
                            setMapOpened(false);
                            setSearchAreaOpened(false);
                            closeMenuMobile();
                        }}>
                            <Link to="/">PAULISTA CULTURAL</Link>
                        </div>
                    </Box>
                    <Hidden smUp>
                        {
                            !loading && menuMobileEnabled ?
                                <IconButton aria-label="close" onClick={closeMenuMobile}>
                                    <CloseIcon className={classes.menuIcon} />
                                </IconButton> :
                                <IconButton aria-label="menu" onClick={openMenuMobile}>
                                    <MenuIcon className={classes.menuIcon} />
                                </IconButton>
                        }   
                    </Hidden>
                    <Hidden only={['xs']}>
                        {
                            !loading &&
                                <List className={classes.menu}>
                                    <ListItemText 
                                        classes={{ primary: [classes.menuItem, mapOpened ? classes.menuItemActive : ""].join(' ') }} 
                                        onClick={toggleMap} primary="INSTITUIÇÕES"
                                    />
                                    <Link to="/agenda" onClick={closeAll}>  
                                        <ListItemText 
                                        classes={{ primary: [classes.menuItem, location.pathname.indexOf("agenda") > -1 && !mapOpened ? classes.menuItemActive : ""].join(' ') }} 
                                        primary="PROGRAMAÇÃO" 
                                    /> 
                                    </Link>
                                    <Link to="/about" onClick={closeAll}>  
                                        <ListItemText 
                                        classes={{ primary: [classes.menuItem, location.pathname.indexOf("about") > -1 && !mapOpened ? classes.menuItemActive : ""].join(' ') }} 
                                        primary="SOBRE" 
                                    />
                                    </Link>
                                    <ListItemIcon onClick={toggleSearchArea}>
                                        {
                                            searchAreaOpened ?
                                                <CloseIcon className={classes.menuItemCloseSearch} /> :
                                                <SearchIcon className={classes.menuItemSearch} />
                                        }
                                    </ListItemIcon>
                                </List>
                        }
                    </Hidden>
                </Box>

                {
                    loading && <Loading width="100%" className={classes.loading}/>
                }

                { menuMobileEnabled && 
                    (
                        <Hidden smUp>
                            <MenuMobile closeMenu={closeMenuMobile}/>
                        </Hidden>
                    ) 
                }

            </header>
            {
                !loading &&
                    <Box component="span">
                        <InstituitionMap open={mapOpened} closeMap={toggleMap} />
                        <SearchArea open={searchAreaOpened} closeSearchArea={toggleSearchArea} />
                    </Box>
            }

        </Box>
    );
}