import React from 'react';
import { Box } from '@material-ui/core';
import Banner from './components/banner';
import VisitMap from './components/visit-map';

export default function Home() {
  return (
    <Box>
      <Banner/>
      <VisitMap/>
    </Box>
  );
}