import { makeStyles } from '@material-ui/core/styles';

const Style = makeStyles((theme) => ({
    body: {
        paddingTop: 60,
        [theme.breakpoints.up("sm")]: {
            paddingTop: 105
        },
    },
}));

export default Style;