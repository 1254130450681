import React, { useState, useEffect } from 'react';
import { Box } from '@material-ui/core';
import Style from './menu-mobile-style';
import { useHistory } from "react-router-dom";
import { getWebData } from "../../../../services/api.service";
import { institutionIsOpen } from "../../../../services/status.service";

export default function MenuMobile({ closeMenu }) {
    
    const classes = Style();
    const history = useHistory();
    const institutions = getWebData().institutions;
    const [status, setStatus] = useState({});

    useEffect(() => {
        setStatus(institutionIsOpen())
    }, []);

    const goTo = (route) => {
        closeMenu();
        history.push(route);
    }

    const MenuItem = (item, i) => {
        return (
            <div className={classes.mobileMenuItem}>
                { <div className={[classes.mobileMenuDot, status[item.id] ? classes.mobileMenuDotOn : classes.mobileMenuDotOff].join(' ')}/> }
                <div className={classes.mobileMenuText} onClick={() => goTo("/instituition/" + item.slug)}>{item.name}</div>
            </div>
        );
    }

    return (
        <Box className={classes.mobileMenuBox}>
            <Box className={classes.mobileMenuBoxContent}>

                { institutions.filter(i => i.show ).map(MenuItem) }

                <div className={classes.mobileMenuItem}>
                    <div className={[classes.mobileMenuText, classes.mobileMenuTextLink].join(' ')} onClick={() => goTo("/about")}>
                        SOBRE
                    </div>
                </div>
            
                <div className={classes.mobileMenuItem}>
                    <div className={[classes.mobileMenuText, classes.mobileMenuTextLink].join(' ')}  onClick={() => goTo("/agenda")} >
                        PROGRAMAÇÃO
                    </div>
                </div> 
            </Box>
        
        </Box>
    );
}