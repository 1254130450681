import { makeStyles } from '@material-ui/core/styles';

const Style = makeStyles((theme) => {

    return ({
        background: {
            width: '100%',
            height: 120,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center top',
            display: 'flex',
            [theme.breakpoints.up("sm")]: {
                height: 400,
            },
        },
        
        boxInfo:{
            width: '100%',
            maxWidth: theme.sizes.pageWidth,
            marginLeft: 'auto',
            marginRight: 'auto',
            paddingTop: 40,
            fontFamily: theme.fonts.primary.family,
            fontWeight: 100,
            marginBottom: 40
        },
        boxInfoContent: {
            width: `calc(100% - ${2*theme.sizes.generalPadding}px)`,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
        boxInfoGrid: {
            marginBottom: 40
        },
        boxInfoText: {
            fontSize: 24,
            lineHeight: '32px',
            paddingRight: 0,
            fontWeight: 500,
            [theme.breakpoints.up("sm")]: {
                paddingRight: 32
            },
        },
        boxInfoRouteLabel: {
            fontWeight: 900,
            color: `rgba(${theme.colors.primary}, 1)`,
            textDecoration: 'underline',
            cursor: 'pointer',
            marginTop: 10
        },
        boxInfoData: {
            backgroundColor: '#F7F7F9',
            position: 'relative',
            marginTop: 30,
            [theme.breakpoints.up("sm")]: {
                marginTop: 0,
            },
        },
        boxInfoDataContent: {
            padding: 24,
            fontSize: 19,
            fontWeight:  500,
            fontFamily: theme.fonts.primary.family,
            lineHeight: '26px'
        },
        boxInfoDataTitle: {
            fontWeight: 900,
            marginBottom: 15
        },
        contentItemImgBox: {
            width: '100%',
            marginBottom: 70,
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: 20
        },
        contentItemImg: {
            width: `100%`,
        },
        contentItemImgSubtitle: {
            fontSize: 16,
            textAlign: 'left',
            backgroundColor: "#f7f7f9",
            paddingLeft: 16,
            marginTop: -10
        },
        contentItem: {
            width: '100%',
            marginLeft: 'auto',
            marginRight: 'auto',
            fontFamily: theme.fonts.primary.family,
            fontWeight: 100,
            fontSize: 24,
            lineHeight: '32px',
            marginBottom: 20

        },
        contentItemTitle: {
            fontWeight: 900
        },
        contentItemSubTitle: {

        },
        contentItemDescription: {
            marginTop: 30,
            fontWeight: 500
        },
        buttons: {
            marginTop: 40,
            marginBottom: 40,
            display: 'flex',
            // justifyContent: 'space-between',
            flexWrap: 'wrap',
            flexGrow: 1
        },
        buttonOption: {
            backgroundColor: 'white',
            fontFamily: theme.fonts.primary.family,
            fontWeight: theme.fonts.primary.weight,
            fontSize: 21,
            borderRadius: 0,
            opacity: 0.7,
            color: `rgba(${theme.colors.primary}, 1)`,
            borderBottom: `3px solid rgba(${theme.colors.primary}, 1)`,
            '&:hover': {
                opacity: 1,
                backgroundColor: 'white',
            },
            display: 'inline-block',
            padding:0,
            minHeight: 0,
            minWidth: 0,
            width: '100%',
            marginBottom: 30,
            [theme.breakpoints.up("sm")]: {
                width: 'auto',
                marginBottom: 0
            }
        },
        buttonOptionLink: {
            width: '100%',
            marginRight: 0,
            [theme.breakpoints.up("sm")]: {
                width: 'auto',
                marginRight: 40,
            },
        },
        buttonSeeHere: {
            backgroundColor: 'white',
            fontFamily: theme.fonts.primary.family,
            fontWeight: theme.fonts.primary.weight,
            fontSize: 21,
            borderRadius: 0,
            opacity: 0.7,
            color: `rgba(${theme.colors.primary}, 1)`,
            borderBottom: `3px solid rgba(${theme.colors.primary}, 1)`,
            '&:hover': {
                opacity: 1,
                backgroundColor: 'white',
            },
            display: 'inline-block',
            width: 'auto',
            marginTop: 20
        },
        breadcrumb: {
            fontSize: 16,
            marginBottom: 10
        },
        breadcrumbItem: {
            textDecoration: 'underline',
            cursor: 'pointer'
        },
        contentTitle: {
            fontSize: 54,
            fontWeight: theme.fonts.primary.weight,
            lineHeight: '55px',
            letterSpacing: '-1.2px',
        }
       
    })
});

export default Style;