import { Box, Hidden } from '@material-ui/core';
import Style from './footer-style';
import { ArrowUpIcon } from '../../packages/icons';
import itau from '../../assets/images/itau.png';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';

const facebookUrl = "https://www.facebook.com/paulistaculturaloficial/";
const instagramUrl = "https://www.instagram.com/paulistacultural.oficial/";
const itauUrl = "https://www.itau.com.br/";

export default function Footer({ loading }) {

    const classes = Style();

    const scrollTop = () =>{
        window.scrollTo({top: 0, behavior: 'smooth'});
    };

    return (
        !loading &&
            <footer className={classes.footerRoot}>
                <Box className={classes.footerBox}>
                    <Hidden only={['xs']}>
                        <Box className={classes.footerLogoBox}>
                            <h1 className={classes.footerLogoText} onClick={scrollTop}>PAULISTA CULTURAL</h1>
                        </Box>
                        <Box className={classes.socialIconsBox}>
                            <a href={facebookUrl} target="_blank" rel="noopener noreferrer" className={classes.socialIconLink}>
                                <FacebookIcon className={classes.socialIcon}/>
                            </a>  
                            <a href={instagramUrl} target="_blank" rel="noopener noreferrer" className={classes.socialIconLink}>
                                <InstagramIcon className={classes.socialIcon}/>
                            </a>  
                        </Box>
                        <Box className={classes.footerPartner}>
                            <div style={{ marginRight: 25}}>
                                PATROCINADOR DO SITE: 
                            </div>
                            <div>
                                <a href={itauUrl} target="_BLANK" rel="noopener noreferrer">
                                    <img src={itau} className={classes.partnerImg} alt="Itaú" />
                                </a>
                            </div>
                            <div>
                                <ArrowUpIcon className={classes.arrowUp} onClick={scrollTop} />
                            </div>
                        </Box>
                    </Hidden>
                    <Hidden smUp>
                        <Box className={classes.footerPartnerMobile}>
                            <div className={classes.footerPartnerContentMobile}>
                                PATROCINADOR DO SITE: 
                            </div>
                            <div className={classes.partnerImgBoxMobile} >
                                <a href={itauUrl} target="_BLANK" rel="noopener noreferrer">
                                    <img src={itau} className={classes.partnerImg} alt="Itaú" />
                                </a>
                            </div>
                        </Box>
                    </Hidden>
                </Box>
                <Hidden smUp>
                    <Box className={classes.footerBoxMobile}>
                        <Box className={classes.footerLogoBoxMobile}>
                            <h1 className={classes.footerLogoTextMobile} onClick={scrollTop}>PAULISTA CULTURAL</h1>
                        </Box>
                        <Box className={classes.socialIconsBoxMobile}>
                            <a href={facebookUrl} target="_blank" rel="noopener noreferrer" className={classes.socialIconLink}>
                                <FacebookIcon className={classes.socialIcon}/>
                            </a>  
                            <a href={instagramUrl} target="_blank" rel="noopener noreferrer" className={classes.socialIconLink}>
                                <InstagramIcon className={classes.socialIcon}/>
                            </a>  
                        </Box>      
                    </Box>
                </Hidden>
            </footer>
    );
}