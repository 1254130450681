import { Box, Hidden, Button } from '@material-ui/core';
import Style from './search-area-style';
import { SearchIcon } from '../../../../packages/icons';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import { useHistory } from "react-router-dom";

var query = "";

export default function SearchArea({ open, closeSearchArea }) {

    const classes = Style();
    const history = useHistory();

    const goSearchByEnter = (e) => {
        if(e.keyCode === 13) goSearch();
    }

    const updateQuery = (e) => {
        query = e.target.value;
    }

    const goSearch = (e) => {
        closeSearchArea();
        history.push("/search/" + query);
    }

    return (    
        open &&    
            <Hidden only={['xs']}>   
                <Box className={classes.boxBackground} > 
                    <Box className={classes.box} >
                        <div className={classes.boxContent}>
                            <div className={classes.boxLimit}>
                                <Input
                                    onKeyDown={goSearchByEnter}
                                    onChange={updateQuery}
                                    className={classes.inputSearch}
                                    placeholder="O que deseja buscar?"
                                    startAdornment={
                                        <InputAdornment style={{marginRight: 20}} position="start">
                                            <SearchIcon />
                                        </InputAdornment>
                                    }
                                />
                                <div className={classes.buttonSearchBox}>
                                    <Button className={classes.buttonSearch} onClick={goSearch}>Buscar</Button>
                                </div>
                            </div>
                        </div>
                    </Box>
                </Box>
            </Hidden>
    );
}