import Style from './app-style';
import Header from '../header';
import Footer from '../footer';
import Home from '../../routes/home';
import Instituition from '../../routes/instituition';
import About from '../../routes/about';
import Search from '../../routes/search';
import Programming from '../../routes/programming';
import Content from '../../routes/content';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Box } from '@material-ui/core';
import ScrollToTop from "../../utils/scrollToTop";
import { useState, useEffect } from 'react';
import { loadWebData } from "../../services/api.service";
import { loadTimesConfig } from "../../services/status.service";

function App() {
  
  const classes = Style();

  const [loading, setLoading] = useState(true);

  useEffect(() => {

    loadWebData()
      .then(() => {
        loadTimesConfig();
        setLoading(false);
      });

  }, []);

  return (
    <Router> 
      {
        <Box>
          <Header loading={loading}/>
          {
            !loading && (
              <Box className={ classes.body }>
                <ScrollToTop />
                <Switch>
                  <Route exact path="/">
                    <Home />
                  </Route>
                  <Route path="/instituition/:slug">
                    <Instituition />
                  </Route>
                  <Route path="/content/:slug">
                    <Content />
                  </Route>
                  <Route exact path="/about">
                    <About />
                  </Route>
                  <Route exact path="/agenda">
                    <Programming />
                  </Route>
                  <Route exact path="/search/:query">
                    <Search />
                  </Route>
                  <Route exact path="/notfound">
                    <Search />
                  </Route>
                  <Route>
                    <Home />
                  </Route>
                </Switch>
              </Box>
            )
          }
          <Footer loading={loading}/>
        </Box>
      }
    </Router>
  );
}

export default App;
