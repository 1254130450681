import { makeStyles } from '@material-ui/core/styles';

const Style = makeStyles((theme) => {
    return ({
        box: {
            height: 800,
            [theme.breakpoints.up("sm")]: {
                height: 700, 
                transform: 'none',
            },
            width: '100%',
            backgroundColor: `rgba(${theme.colors.primary})`
        },
        boxContent: {
            marginLeft: 'auto', 
            marginRight: 'auto',
            width: '100%',
            maxWidth: theme.sizes.pageWidth,
        },
        title: {
            fontFamily: theme.fonts.primary.family,
            fontWeight: theme.fonts.primary.weight,
            color: "white",
            fontSize: 24,
            paddingTop: 40,
            paddingLeft: theme.sizes.generalPadding,
            textAlign: 'center',
            [theme.breakpoints.up("sm")]: {
                textAlign: 'left',
                fontSize: 32,
            },
        },
        boxMap: {
            width: '85%',
            marginRight: 'auto',
            marginLeft: 'auto',
            paddingTop: 60
        },
        boxMapMobile: {
            textAlign: 'center',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        localBoxTop: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            transform: 'rotate(90deg)',
            position: 'absolute',
            marginTop: 270,
            marginLeft: 120,
            [theme.breakpoints.up("sm")]: {
                position: 'relative',
                marginTop: 0,
                marginLeft: 0,
                transform: 'none',
            },
        },
        localBoxBottom: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            transform: 'rotate(90deg)',
            marginLeft: -170,
            marginTop: -260,
            [theme.breakpoints.up("sm")]: {
                position: 'relative',
                marginTop: 0,
                marginLeft: 0,
                transform: 'none',
            },
        },
        localBoxMobileLeft: {
            width: 150,
            height: 600,
            marginRight: 25
        },
        localBoxMobileRight: {
            width: 180,
            height: 600
        },
        localBoxMobileRightContent: {
            width: '100%',
            height: '100%',
            marginTop: 30,
            marginLeft: -15
        },
        localText: {
            fontFamily: theme.fonts.primary.family,
            fontWeight: theme.fonts.primary.weight,
            color: "white",
            fontSize: '1.6rem',
            transform: 'rotate(45deg)',
            letterSpacing: -2,
            lineHeight: 0.9,
            [theme.breakpoints.up("sm")]: {
                fontSize: 40,
                transform: 'rotate(-45deg)',
            }
        },
        localTextString: {
            '&:hover': {
                "& $localNumber": {
                    color: 'rgba(255, 255, 255, 2)',
                },
                "& $localName": {
                    color: 'rgba(255, 255, 255, 2)',
                }
            }
        },
        localName: {
            color: '#1D1D1B',
            cursor: 'pointer',
            transition: 'all 0.2s linear'
        },
        localNumber: {
            cursor: 'pointer',
            [theme.breakpoints.up("sm")]: {
                color: 'rgba(255, 255, 255, 0.75)',
            },
            transition: 'all 0.2s linear'
        },
        avenueImg: {
            width: '100%', 
            marginTop: 270, 
            marginBottom: 70,
            transform: 'rotate(92deg)',
            [theme.breakpoints.up("sm")]: {
                marginTop: 180, 
                transform: 'none',
            },
        },
        avenueImgMobile: {
            height: 600,
        },
        localTextOne: {
            height: '25%',
            textAlign: 'left',
            marginLeft: -20,
            marginTop: 50,
            [theme.breakpoints.up("sm")]: {
                marginBottom: -130,
                width: '25%',
                marginLeft: 0,
                height: 'auto',
                marginTop: 0,
            },
        },
        localTextTwo: {
            textAlign: 'left',
            height: '30%',
            marginLeft: -60,
            marginTop: 30,
            [theme.breakpoints.up("sm")]: {
                width: '40%',
                marginLeft: 'auto',
                marginTop: 'auto',
                marginBottom: -30,
                height: 'auto',
            },
        },
        localTextThree: {
            textAlign: 'left',
            height: '18%',
            marginTop: 120,
            [theme.breakpoints.up("sm")]: {
                marginBottom: -85,
                textAlign: 'left',
                marginTop: 'auto',
                width: '25%',
                height: 'auto',
            },
        },
        localTextFour: {
            textAlign: 'left',
            height: '25%',
            marginTop: 160,
            [theme.breakpoints.up("sm")]: {
                width: '25%',
                textAlign: 'right',
                height: 'auto',
                marginTop: 0,
            },
        },
        localTextFive: {
            textAlign: 'left',
            height: '20%',
            [theme.breakpoints.up("sm")]: {
                width: '20%',
                textAlign: 'right',
                height: 'auto',
            },
        },
        localTextSix: {
            textAlign: 'left',
            height: '20%',
            [theme.breakpoints.up("sm")]: {
                width: '25%',
                height: 'auto',
                textAlign: 'right',
            },
        },
        localTextSeven: {
            textAlign: 'left',
            height: '20%',
            [theme.breakpoints.up("sm")]: {
                width: '25%',
                height: 'auto',
                marginTop: -30
            },
        },
        
    })
});


export default Style;