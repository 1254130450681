import React, { useState, useEffect } from 'react';
import { Box, Grid } from '@material-ui/core';
import Style from './search-style';
import 'react-dropdown/style.css';
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from  "react-datepicker";
import { ptBR } from 'date-fns/esm/locale';
import { useParams } from "react-router";
import { getSearch } from '../../services/api.service';
import { Link } from "react-router-dom";
import Loading from '../../components/loading';

registerLocale('pt-BR', ptBR);


export default function Search() {

  const classes = Style();
  const { query } = useParams();
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  
  useEffect(() => {

    setLoading(true);
    
    getSearch(query).then((data) => {
      setPosts(data);
      setLoading(false);
    });

  }, [query]);

  const PostItem = (item) => {
    return (
      <Link to={`/content/${item.slug}`}>  
        <Grid 
          container
          className={classes.item}
        >
          <Grid 
            item
            xs={12}
            sm={3}
          >
            <img alt={item.title} src={item.bannerUrl} style={{ width: '100%', height: '100%'}}/>
          </Grid>
          <Grid 
            item
            xs={12}
            sm={9}
            className={classes.itemContent}
          >
            <div className={classes.postType}>
              {
                item.type === "EVENT" ? "EVENTO" : "EXIBIÇÃO"
              }
            </div>
            <div className={classes.postTitle}>
              { item.title }
            </div>
            {
              item.tags.split(",").map(tag => <span className={classes.postTag}>{tag.toUpperCase()}</span>)
            }
              
          </Grid>
        </Grid>
      </Link>
    );
  }

  return (
    <Box>

      <Box className={classes.boxInfo}>

        <Box className={classes.boxInfoContent}>
          <div className={classes.itemsTitle}>
            Resultados para: <span className={classes.itemsTitleResult}>{query}</span>
          </div>

          <hr className={classes.itemsTitleLine}/>

          {
            loading ? 
              <Loading width="50%"/> : 
              posts.map(PostItem)
          }
          
        </Box>
      </Box>
    </Box>
  );
}