export const sortAsc = (arr, attr) => {

    function compare(a, b) {
        const itemA = a[attr] ? a[attr] : 0;
        const itemB = b[attr] ? b[attr] : 0;
      
        let comparison = 0;
        if (itemA > itemB) {
          comparison = 1;
        } else if (itemA < itemB) {
          comparison = -1;
        }
        return comparison;
    }

    arr.sort(compare);
}

export const sortDesc = (arr, attr) => {

  function compare(a, b) {
      const itemA = a[attr] ? a[attr] : 0;
      const itemB = b[attr] ? b[attr] : 0;
    
      let comparison = 0;
      if (itemA > itemB) {
        comparison = -1;
      } else if (itemA < itemB) {
        comparison = 1;
      }
      return comparison;
  }

  arr.sort(compare);
}


  
  