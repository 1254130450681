export const colors  = {
    primary: '90, 90, 90',
    secondary: '10, 20, 45',
    tertiary: '0, 0, 0',
};

export const fonts = {
    primary: {
        family: 'TradeGothic, serif',
        weight: 900,
    },
};

export const sizes = {
    generalPadding: 16,
    pageWidth: 1180
};