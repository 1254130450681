import { environment } from "../enviroments/environment";
import { institutionIsOpenInDate } from "./status.service";
import moment from 'moment';

const data = {};

export const api = environment.api;

export const loadWebData = async () => {
  try {
    const webData = await fetch(`${api}/api/web`);
    const json = await webData.json();
    data.web = json.data;

    data.institutionMapById = data.web.institutions.reduce(function(result, institution) {
      result[institution.id] = institution;
      return result;
    }, {});

  } catch(e) {
    console.log("Error when load web data. ", e)
  }
}

export const getPostById = async (id) => {
  try {
    const data = await fetch(`${api}/posts/${id}`);
    const json = await data.json();

    return json.data;

  } catch(e) {
    console.log("Error when getPostById ", e)
  }
}

export const getPostBySlug = async (slug) => {
  try {
    const data = await fetch(`${api}/posts/${slug}`);
    const json = await data.json();

    return json.data;

  } catch(e) {
    console.log("Error when getPostBySlug ", e)
  }
}


export const getWebData = () => {
  try {
    return JSON.parse(JSON.stringify(data.web));
  } catch(e) {
   console.log("Error when get web data. ", e)
  }
}

export const getInstitutionById = (id) => {
  try {
    return data.institutionMapById[id];
  } catch(e) {
   console.log("Error when get web data. ", e)
  }
}

export const getProgramming = async (institutionId, tag, date) => {
  try {
    let url = `${api}/posts/programming?`;

    if(institutionId) url = url + "institutionId="+institutionId+"&";
    if(tag) url = url + "tag="+tag+"&";
    if(date) url = url + "date="+date+"&";

    const posts = await fetch(url);
    const json = await posts.json();

    json.data.forEach(element => {
      element.items = element.items.filter((post) => {
        return post.digital || institutionIsOpenInDate(post.institutionId, moment(element.date, 'YYYY-MM-DD'));
      });
    });

    return json.data;

  } catch(e) {
    console.log("Error when load web data. ", e)
  }
}


export const getSearch = async (text) => {
  try {
    let url = `${api}/posts/search?text=${text}`;

    const posts = await fetch(url);
    const json = await posts.json();
   
    return json.data;

  } catch(e) {
    console.log("Error when load web data. ", e)
  }
}
