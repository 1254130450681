import { makeStyles } from '@material-ui/core/styles';

const Style = makeStyles((theme) => ({
    colorPrimary: {
        backgroundColor: '#ccc',
    },
    barColorPrimary: {
        backgroundColor: '#000',
    },
    paulistaLoading: {
        textAlign: 'center',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: 50,
        marginBottom: 50
    },
    paulistaLoadingImage: {
        width: 60,
        borderRadius: 30,
        marginBottom: 5
    }
}));

export default Style;