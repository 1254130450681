import React, { useState, useEffect, forwardRef, useRef } from 'react';
import { Box, Grid } from '@material-ui/core';
import Style from './programming-style';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import { ArrowRightFilterIcon } from '../../packages/icons';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from  "react-datepicker";
import { ptBR } from 'date-fns/esm/locale';
import { getProgramming, getWebData } from '../../services/api.service';
import { Link } from "react-router-dom";
import Loading from '../../components/loading';
import CloseIcon from '@material-ui/icons/Close';
import * as moment from 'moment';

registerLocale('pt-BR', ptBR);

var selectedInstitution = null;
var selectedInstitutionLabel = null;
var selectedTag = null;
var selectedDate = null;
var selectedFormatedDate = null;


export default function Programming() {

  const classes = Style();
  const webData = getWebData();
  const tags = webData.tags;

  const [currentDate, setCurrentDate] = useState();
  const [posts, setPosts] = useState([]);
  const [allPosts, setAllPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  
  let listRef = useRef(null);

  const institutions = webData.institutions.map(data => {
    return {
      label: data.name,
      value: data.id
    }
  });

  institutions.unshift({
    label: "TODAS",
    value: null
  });

  tags.unshift({
    label: "TODAS",
    value: null
  });

  function loadData(isToScroll) {
    setLoading(true);

    getProgramming(selectedInstitution, selectedTag, selectedDate).then((data) => {
      setAllPosts(data);
      setPosts(data.slice(0, 3));
      setLoading(false);

      if(isToScroll && listRef.current && listRef.current.offsetTop){
        window.scrollTo(0, listRef.current.offsetTop);
      }
    });
  }
  
  useEffect(() => {
    loadData();
  }, []);

  const handleDateSelect = (date) => {
    selectedDate = date;
    selectedFormatedDate = moment(date).locale('pt-br').format('DD MMM')
    loadData(true);
  }

  const handleDateChange = (value) => {
    setCurrentDate(value)
  }

  const seeMore = () => {
    setPosts(allPosts);
  }

  const handleInstitutionSelect = (institution) => {
    selectedInstitution = institution.value;
    selectedInstitutionLabel = institution.label;
    loadData(true);
  };

  const handleeTagSelect = (tag) => {
    selectedTag = tag.value;
    loadData(true);
  };

  const removeTagFilter = () => {
    selectedTag = null;
    loadData(true);
  };

  const removeInstitutionFilter = () => {
    selectedInstitution = null;
    selectedInstitutionLabel = null;
    loadData(true);
  };

  const removeDateFilter = () => {
    selectedDate = null;
    selectedFormatedDate = null;
    loadData(true);
  };

  const PostItem = (item) => {
    return (
      <Link to={`/content/${item.slug}`}>  
        <Grid 
          container
          className={classes.item}
        >
          <Grid 
            item
            xs={12}
            sm={3}
          >
            <img alt={item.title} src={item.bannerUrl} style={{ width: '100%', height: '100%'}}/>
          </Grid>
          <Grid 
            item
            xs={12}
            sm={9}
            className={classes.itemContent}
          >
            <div className={classes.postType}>
              {
                item.type === "EVENT" ? "EVENTO" : "EXIBIÇÃO"
              }
            </div>
            <div className={classes.postTitle}>
              { item.title }
            </div>
            {
              item.tags.split(",").map(tag => <span className={classes.postTag}>{tag.toUpperCase()}</span>)
            }
              
          </Grid>
        </Grid>
      </Link>
    );
  }

  const DayItem = (data) => {
    return (
      data.items.length ?
        <Box component='span'>
          <div className={classes.itemsTitleToday}>
            <span className={classes.capitalize}>{ data.weekday }</span>, { data.day } de <span className={classes.capitalize}>{ data.month }</span>
          </div>
          { data.items.map(PostItem) }
        </Box>
      :
      null
    );

  }


  const DatePickerCustomInput = forwardRef(({ onClick, value }, ref) => (
    selectedDate ?
      <input type="text" readOnly className={classes.datePickerCustomInput} ref={ref} value={value} onClick={onClick}/> :
      <input type="text" readOnly className={classes.datePickerCustomInput} ref={ref} value="TODAS" onClick={onClick}/>
  ));

  return (
    <Box>
      <Box className={classes.background}>
        <div className={classes.backgroundContent}>
          <div className={classes.pageTitle}>Programação</div>
          <div className={classes.filterBox}>
            <Grid 
              container
              className={classes.boxInfoGrid}
            >
              <Grid 
                item
                xs={12}
                sm={4}
              >
                <div>
                  <div className={classes.filterLabel}>
                    Instituições
                  </div>
                  <div className={classes.filterDropdownBox}>
                    <Dropdown
                      onChange={handleInstitutionSelect}
                      options={institutions} 
                      value={selectedInstitution}
                      placeholder="TODAS"
                      className='filterDropdown'
                      arrowClosed={<ArrowRightFilterIcon className={classes.filterDropdownArrow}/>}
                      arrowOpen={<ArrowRightFilterIcon className={classes.filterDropdownArrow}/>}
                    />
                  </div>
                </div>
              </Grid>
              <Grid 
                item
                xs={12}
                sm={4}
              >
                <div>
                  <div className={classes.filterLabel}>
                    Tags
                  </div>
                  <div className={classes.filterDropdownBox}>
                    <Dropdown 
                      onChange={handleeTagSelect}
                      options={tags}
                      placeholder="TODAS"
                      className='filterDropdown'
                      value={selectedTag}
                      arrowClosed={<ArrowRightFilterIcon className={classes.filterDropdownArrow}/>}
                      arrowOpen={<ArrowRightFilterIcon className={classes.filterDropdownArrow}/>}
                    />
                  </div>
                </div>
              </Grid>
              <Grid 
                item
                xs={12}
                sm={4}
              >
                <div>
                  <div className={classes.filterLabel}>
                    Data
                  </div>
                  <div className={classes.filterDropdowLast}>
                    <div className="customDatePickerWidth">
                      <DatePicker
                        onSelect={handleDateSelect}
                        onChange={handleDateChange}
                        closeOnScroll={true}
                        customInput={<DatePickerCustomInput />}
                        popperPlacement="bottom-start"
                        selected={currentDate}
                        dateFormat="EEEE, dd MMMM"
                        locale="pt-BR"
                        popperModifiers={{
                          flip: {
                            enabled: false
                          },
                          preventOverflow: {
                            enabled: true,
                            escapeWithReference: false,
                            boundariesElement: 'viewport'
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
              </Grid>
              
            </Grid>
          </div>
        </div>
      </Box>

      <Box className={classes.boxInfo} style={{ marginBottom: 500}} >

        <Box className={classes.boxInfoContent}>
          <div className={classes.itemsTitle} ref={listRef}>
            O que está rolando 
            {
              selectedInstitution && 
                <div className={classes.filterItemToRemove}>{selectedInstitutionLabel} <CloseIcon className={classes.filterItemToRemoveCloseIcon} onClick={() => removeInstitutionFilter()}/></div>
            } 

            {
              selectedTag && 
                <div className={classes.filterItemToRemove}>{selectedTag} <CloseIcon className={classes.filterItemToRemoveCloseIcon} onClick={() => removeTagFilter()}/></div>
            } 
            
            {
              selectedDate && 
                <div className={classes.filterItemToRemove}><span className={classes.capitalize}>{selectedFormatedDate}</span> <CloseIcon className={classes.filterItemToRemoveCloseIcon} onClick={() => removeDateFilter()}/></div>
            }
            
          </div>

          <hr className={classes.itemsTitleLine}/>
          {
            loading ? 
              <Loading width="50%"/> :
              <Box component="span">
                { posts.length > 0 && posts[0].items.length > 0 &&
                  (
                    <Box component="span">
                      <div className={classes.itemsTitleToday}>
                        { posts[0].weekday }, { posts[0].day } de <span className={classes.capitalize}>{ posts[0].month }</span>
                      </div>

                      { posts[0].items.map(PostItem) }

                    </Box>
                  )
                }
                {
                  posts.length > 1 &&
                    <div className={classes.itemsTitleNext}>
                      Próximos Eventos
                    </div>
                }

                { posts.slice(1).map(DayItem) }

                { allPosts.length > posts.length &&
                    <button className={classes.seeMoreButton} onClick={() => seeMore() }>VER MAIS</button>
                }
              </Box>
          }

        </Box>
      </Box>
    </Box>
  );
}