import { makeStyles } from '@material-ui/core/styles';

const Style = makeStyles((theme) => {
    return ({
        box: {
            height: 500,
            width: '100%',
            top: 0,
            right: 0,
            left: 0,
            backgroundColor: 'white',
            boxShadow: '0 4px 10px -5px rgba(0,0,0,0.2)'
        },
        boxContent: {
            marginLeft: 'auto', 
            marginRight: 'auto',
            width: '100%',
            maxWidth: theme.sizes.pageWidth,
        },
        boxMap: {
            width: '70%',
            marginRight: 'auto',
            marginLeft: 'auto',
            paddingTop: 30
        },
        localBoxTop: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            position: 'relative',
        },
        localBoxBottom: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            position: 'relative',
        },
        localText: {
            fontFamily: theme.fonts.primary.family,
            fontWeight: theme.fonts.primary.weight,
            color: "white",
            fontSize: 35,
            transform: 'rotate(-45deg)',
            letterSpacing: -2,
            lineHeight: 0.9,
        },
        localTextString: {
            '&:hover': {
                "& $localName": {
                    color: 'rgba(199,199,199, 1)',
                }
            }
        },
        localName: {
            color: '#1D1D1B',
            cursor: 'pointer',
            transition: 'all 0.2s linear'
        },
        localNumber: {
            cursor: 'pointer',
            color: 'rgba(199, 199, 199, 1)',
            transition: 'all 0.2s linear'
        },
        avenueImg: {
            width: '100%', 
            marginTop: 160, 
            marginBottom: 70
        },
        localTextOne: {
            textAlign: 'left',
            marginBottom: -130,
            width: '25%',
        },
        localTextTwo: {
            textAlign: 'left',
            width: '40%',
            marginLeft: 'auto',
            marginBottom: -55,
        },
        localTextThree: {
            textAlign: 'left',
            marginBottom: -105,
            width: '25%',
        },
        localTextFour: {
            width: '38%',
            textAlign: 'right',
            marginRight: -40,
            marginTop: 30
        },
        localTextFive: {
            textAlign: 'right',
            width: '25%',
        },
        localTextSix: {
            width: '25%',
            textAlign: 'right',
            marginRight: -30,
            marginTop: -50
        },
        localTextSeven: {
            textAlign: 'right',
            width: '28%',
            marginTop: -30
        },
        
    })
});


export default Style;