import { makeStyles } from '@material-ui/core/styles';

const Style = makeStyles((theme) => {

    return ({
        background: {
            width: '100%',
            height: 120,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center top',
            display: 'flex',
            [theme.breakpoints.up("sm")]: {
                height: 400,
            },
        },
        backgroundContent: {
            marginBottom: 16,
            [theme.breakpoints.up("sm")]: {
                marginBottom: 40,
            },

        },
        backgroundContentLeft: {
            display: 'flex',
        },
        backgroundContentRight: {
            display: 'flex',
            justifyContent: 'flex-end',
            paddingRight: theme.sizes.generalPadding,
            marginBottom: 20,
            [theme.breakpoints.up("sm")]: {
                marginBottom: 56,
            },
        },
        backgroundContainer: {
            maxWidth: 1180,
            marginLeft: 'auto',
            marginRight: 'auto'
        },
        buttonSchedule: {
            backgroundColor: 'white',
            borderRadius: 2,
            padding: 10,
            paddingLeft: 20,
            paddingRight: 20,
            fontFamily: theme.fonts.primary.family,
            fontWeight: theme.fonts.primary.weight,
            fontSize: 18,
            color: `rgba(${theme.colors.primary}, 1)`,
            borderBottomRightRadius: 0,
            borderBottomLeftRadius: 0,
            borderBottom: `3px solid rgba(${theme.colors.primary}, 1)`,
            opacity: 0.9,
            '&:hover': {
                opacity: 1,
                backgroundColor: 'white',
            },
        },
        instituitionTitle: {
            color: 'white',
            fontFamily: theme.fonts.primary.family,
            fontWeight: theme.fonts.primary.weight,
            fontSize: 25,
            display: 'inline-block',
            paddingLeft: theme.sizes.generalPadding,
            [theme.breakpoints.up("sm")]: {
                fontSize: 42,
            },
        },
        instituitionStatus: {
            color: 'white',
            fontFamily: theme.fonts.primary.family,
            fontWeight: theme.fonts.primary.weight,
            fontSize: 15,
            textTransform: 'uppercase',
            marginLeft: 30,
            display: 'flex',
            alignItems: 'center',
            [theme.breakpoints.up("sm")]: {
                fontSize: 22,
            },
        },
        dotIcon: {
            marginRight: 5,
            color: '#72D73A',
            fontSize: 20,
            [theme.breakpoints.up("sm")]: {
                fontSize: 30,
            },
        },
        dotIconOff: {
            color: 'rgba(240,65,50,0.99)',
        },
        boxInfo:{
            width: '100%',
            maxWidth: theme.sizes.pageWidth,
            marginLeft: 'auto',
            marginRight: 'auto',
            paddingTop: 40,
            fontFamily: theme.fonts.primary.family,
            fontWeight: 100
        },
        boxInfoContent: {
            width: `calc(100% - ${2*theme.sizes.generalPadding}px)`,
            marginLeft: 'auto',
            marginRight: 'auto'
        },
        boxInfoText: {
            fontSize: 24,
            lineHeight: '32px',
            fontWeight: 500,
            paddingRight: 0,
            [theme.breakpoints.up("sm")]: {
                paddingRight: 32
            },
        },
        boxInfoRouteLabel: {
            fontWeight: 900,
            color: `rgba(${theme.colors.primary}, 1)`,
            textDecoration: 'underline',
            cursor: 'pointer',
            marginTop: 10
        },
        boxInfoData: {
            backgroundColor: '#F7F7F9',
            position: 'relative',
            marginTop: 30,
            [theme.breakpoints.up("sm")]: {
                marginTop: 0,
            },
        },
        boxInfoDataContent: {
            padding: 24,
            fontSize: 19,
            fontWeight: 500,
            fontFamily: theme.fonts.primary.family,
            lineHeight: '26px'
        },
        boxInfoDataTitle: {
            fontWeight: 900,
            marginBottom: 15
        },
        mainImgBox: {
            width: '100%',
            marginTop: 40,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
        mainImg: {
            width: `100%`,
        },
        highlightBox: {
            marginTop: 40
        },
        highlightTitle: {
            fontFamily: theme.fonts.primary.family,
            fontWeight: theme.fonts.primary.weight,
            fontSize: 28,
            marginBottom: 40

        },
        highlightItem: {
            width: '100%',
            height: 420,
            marginTop: 20,
            opacity: 0.8,
            '&:hover': {
                opacity: 1,
            },
            [theme.breakpoints.up("sm")]: {
                marginTop: 0,
            },
            cursor: 'pointer'
        },
        highlightItemPrimary: {
            backgroundColor: `rgba(${theme.colors.primary}, 1)`
        },
        highlightItemSecondary: {
            backgroundColor: `rgba(${theme.colors.secondary}, 1)`
        },
        highlightItemTertiary: {
            backgroundColor: `rgba(${theme.colors.tertiary}, 1)`
        },
        highlightItemPhotoContainer: {
            height: '50%'

        },
        highlightItemPhoto: {
            width: '100%',
            height: '100%'
        },
        highlightItemInfoContainer: {
            height: '50%',
            padding: theme.sizes.generalPadding,
            fontFamily: theme.fonts.primary.family,
            fontWeight: theme.fonts.primary.weight,
            color: 'white'

        },
        highlightItemInfoTitle: {
            fontSize: 20
        },
        highlightItemInfoText: {
            height: 110,
            marginTop: 10,
            fontSize: 28,
            overflow: 'hidden'

        },
        arrowRight: {
            fontSize: 35,
            color: 'white',
            marginTop: 0
        },
        arrowRightDownMenu: {
            fontSize: 40,
            color: 'black',
            marginLeft: 20,
            position: 'absolute',
            right: theme.sizes.generalPadding,
            [theme.breakpoints.up("sm")]: {
                marginTop: 0,
                position: 'relative',
            },
        },
        downMenuBox:{
            marginLeft: 'auto',
            marginRight: 'auto',
            paddingTop: 60,
            fontFamily: theme.fonts.primary.family,
            fontWeight: theme.fonts.primary.weight,
            marginBottom: 50
        },
        downMenuBoxItem: {
            fontSize: 30,
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            marginBottom: 40,
            width: '100%',
            [theme.breakpoints.up("sm")]: {
                fontSize: 48,
            },

        },
        socialIcon: {
            fontSize: 35,
            marginRight: 20,
            cursor: 'pointer',
            opacity: 0.75,
            '&:hover': {
                opacity: 1,
            },

        },
        logoImg: {
            maxWidth: '100%',
            maxHeight: 80,
            marginBottom: 32,
            marginTop: 20
        }
    })
});

export default Style;