import React, { useState, useEffect } from 'react';
import { Box, Button, Hidden, Grid } from '@material-ui/core';
import Style from './instituition-style';
import { ArrowRightIcon } from '../../packages/icons';
import { useParams } from "react-router";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';
import { getWebData } from "../../services/api.service";
import { institutionIsOpen } from "../../services/status.service";
import { Link } from "react-router-dom";

export default function Instituition() {

  const classes = Style();
  const { slug } = useParams();
  const webData = getWebData();
  const current = webData.institutions.filter(data => data.slug === slug)[0];
  const [status, setStatus] = useState(false);
  const posts = webData.posts.filter(data => data.institutionId === current.id);

  useEffect(() => {
    setStatus(institutionIsOpen(current.id));
  }, [slug]);

  const renderHighlights = (item, index) => {
    return (
      <Grid 
        item
        xs={12}
        sm={4}
        key={"highlight-"+index}
      >
        <Link to={`/content/${item.slug}`}> 
          <div className={
            [
              classes.highlightItem, 
              index === 0 ? classes.highlightItemPrimary : index === 1 ? classes.highlightItemSecondary : classes.highlightItemTertiary
            ].join(' ')}
          >
            <div className={classes.highlightItemPhotoContainer}>
                <img src={item.bannerUrl} className={classes.highlightItemPhoto} alt={item.title}/>
            </div>
            <div className={classes.highlightItemInfoContainer}>
                <div className={classes.highlightItemInfoTitle}>{ item.type === "EXHIBITION" ? "EXIBIÇÃO" : item.type === "EVENT" ? "EVENTO" : "NOTÍCIA" }</div>
                <div className={classes.highlightItemInfoText}>{ item.title }</div>
                <ArrowRightIcon className={classes.arrowRight} />
            </div>
          </div>
        </Link>
      </Grid>
    );
  }

  const renderMenuItem = (item) => {
    return (
      item.hideInMobile ?
      (
        <Hidden only={['xs']} key={"menu-h-"+item.label}>
          <div className={classes.downMenuBoxItem} key={"menu-"+item.label}>
            <a href={item.url} target="_blank" rel="noopener noreferrer">{ item.label }<ArrowRightIcon className={classes.arrowRightDownMenu} /></a>
          </div>
        </Hidden>
      )
      :
      <div className={classes.downMenuBoxItem} key={"menu-"+item.label}>
        { 
          item.labelMobile ?  (
            <span>
              <Hidden only={['xs']}><a href={item.url} target="_blank" rel="noopener noreferrer">{ item.label }</a></Hidden>
              <Hidden smUp><a href={item.url} target="_blank" rel="noopener noreferrer">{ item.labelMobile }</a></Hidden>
              <ArrowRightIcon className={classes.arrowRightDownMenu} />
            </span>
          )
          :
          <a href={item.url} target="_blank" rel="noopener noreferrer">
            <span>
              { item.label }
              <ArrowRightIcon className={classes.arrowRightDownMenu} />
            </span>
          </a>
        }
      </div>
    );
  }

  return (
    <Box>
      <Box className={classes.background} style={{ backgroundImage: `url(${current.bannerImageUrl})`}}>
          <Grid 
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-end"
            className={classes.backgroundContainer}
          >
            <Grid item xs={9}>
              <Grid 
                container
                direction="row"
                justify="flex-start"
                alignItems="center"
                className={classes.backgroundContent}
              >
                <Grid item xs={12} className={classes.backgroundContentLeft}>
                  <div className={classes.instituitionTitle}>{current.name}</div>
                  <Hidden only={['xs']}>
                    { status ?
                      <div className={classes.instituitionStatus}>
                        <FiberManualRecordIcon className={classes.dotIcon} />Aberto
                      </div>
                      :
                      <div className={classes.instituitionStatus}>
                       <FiberManualRecordIcon className={[classes.dotIcon, classes.dotIconOff].join(' ')} />Fechado
                      </div>
                    }
                  </Hidden>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={3}>
              <Grid 
                container 
              >
                <Grid 
                  item 
                  xs={12} 
                  className={classes.backgroundContentRight}
                >
                  <Hidden only={['xs']}>
                    <a href={current.scheduleUrl} target="_blank" rel="noopener noreferrer">
                      <Button className={classes.buttonSchedule}>SAIBA MAIS</Button>
                    </a>
                  </Hidden>
                  <Hidden smUp>
                      { status ?
                        <div className={classes.instituitionStatus}>
                          <FiberManualRecordIcon className={classes.dotIcon} />Aberto
                        </div>
                        :
                        <div className={classes.instituitionStatus}>
                        <FiberManualRecordIcon className={[classes.dotIcon, classes.dotIconOff].join(' ')} />Fechado
                        </div>
                      }
                  </Hidden>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
      </Box>

      <Box className={classes.boxInfo}>
        <Box className={classes.boxInfoContent}>
          <Grid 
            container
          >
            <Grid 
              item
              xs={12}
              sm={6}
              className={classes.boxInfoText}
            >
              <div dangerouslySetInnerHTML={{ __html: current.description }}/>
            </Grid>
            <Grid 
              item
              xs={12}
              sm={6}
            >
              <div className={classes.boxInfoData}>
                <div className={classes.boxInfoDataContent}>
                  { current.logoImageUrl && current.logoImageUrl.length > 10 &&
                    <img src={current.logoImageUrl} className={classes.logoImg} alt={current.name} />
                  }
                  <div dangerouslySetInnerHTML={{ __html: current.featured }}/>
                  { current.facebookUrl &&
                    <a href={current.facebookUrl} target="_blank" rel="noopener noreferrer">
                      <FacebookIcon className={classes.socialIcon}/>
                    </a>  
                  }
                  { current.twitterUrl &&
                    <a href={current.twitterUrl} target="_blank" rel="noopener noreferrer">
                      <TwitterIcon className={classes.socialIcon}/>
                    </a>
                  }
                  { current.instagramUrl &&
                    <a href={current.instagramUrl} target="_blank" rel="noopener noreferrer">
                      <InstagramIcon className={classes.socialIcon}/>
                    </a>
                  }
                </div>
              </div>
            </Grid>
          </Grid>
          
          <Box className={classes.mainImgBox}>
            <img src={current.mainImageUrl} className={classes.mainImg} alt={current.name}/>
          </Box>
          
          { posts &&
            <Box className={classes.highlightBox}>
              <div className={classes.highlightTitle}>
                Destaques
              </div>
              <Grid container >
                { posts.slice(0,3).map((item, i) => renderHighlights(item, i) ) }
              </Grid>
            </Box> 
          }
        
          <Box className={classes.downMenuBox}>
            { current.menu.map(renderMenuItem) }
          </Box>
        </Box>
      </Box>
    </Box>
  );
}