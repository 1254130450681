import React, { useState, useEffect } from 'react';
import { Box, Button, Grid } from '@material-ui/core';
import Style from './content-style';
import { useParams, useHistory } from "react-router";
import { getWebData, getInstitutionById, getPostBySlug } from "../../services/api.service";
import { sortAsc } from "../../utils/sortArray";
import Loading from '../../components/loading';
import { stripHtml } from '../../utils/string';

import { withStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { FacebookShareButton, FacebookIcon,TwitterShareButton, TwitterIcon, LinkedinShareButton, LinkedinIcon, WhatsappIcon, WhatsappShareButton } from "react-share";

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
    marginTop: 5
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

export default function Content() {

  const classes = Style();
  const { slug } = useParams();
  const [loading, setLoading] = useState(true);
  const [currentContent, setCurrentContent] = useState(null);
  const [currentInstituition, setCurrentInstituition] = useState(null);
  const [anchorShareMenu, setAnchorShareMenu] = React.useState(null);
  const [hasFeatured, setHasFeatured] = React.useState(true);
  const history = useHistory();

  const setAllContent = (data) => {
    sortAsc(data.sessions, "position");

    setCurrentContent(data);
    setCurrentInstituition(getInstitutionById(data.institutionId));
    setHasFeatured(stripHtml(data.featured).trim().length > 1)
    setLoading(false);
  };

  const getCurrentUrl = () => {
    return window.location.href;
  };

  useEffect(() => {

    setLoading(true);

    const posts = getWebData().posts.filter(post => post.slug === slug);

    if(posts && posts.length){
      setAllContent(posts[0]);
    } else {
      getPostBySlug(slug).then((data) => setAllContent(data)).catch(() => history.push("/"));
    }

  }, [slug]);

  const handleClickShareMenu = (event) => {
    setAnchorShareMenu(event.currentTarget);
  };

  const handleCloseShareMenu = () => {
    setAnchorShareMenu(null);
  };

  const ShareMenu = () => {
    return <StyledMenu
        id="share-menu"
        anchorEl={anchorShareMenu}
        keepMounted
        open={Boolean(anchorShareMenu)}
        onClose={handleCloseShareMenu}
      >
        <MenuItem onClick={handleCloseShareMenu}>
          <FacebookShareButton
            url={getCurrentUrl()}
            quote={currentContent.title}
          >
            <FacebookIcon size={32} round />
          </FacebookShareButton>
        </MenuItem>
        <MenuItem onClick={handleCloseShareMenu}>
          <TwitterShareButton
            url={getCurrentUrl()}
            title={currentContent.title}
          >
            <TwitterIcon size={32} round />
          </TwitterShareButton>
        </MenuItem>
        <MenuItem onClick={handleCloseShareMenu}>
          <LinkedinShareButton url={getCurrentUrl()}>
            <LinkedinIcon size={32} round />
          </LinkedinShareButton>
        </MenuItem>
        <MenuItem onClick={handleCloseShareMenu}>
          <WhatsappShareButton 
            url={getCurrentUrl()}
            title={currentContent.title}
          >
            <WhatsappIcon size={32} round />
          </WhatsappShareButton>
        </MenuItem>
      </StyledMenu>
  }

  const renderItem = (item) => {
    return (
      <Box className={classes.contentItem} key={item.subtitle}>

        { item.title && item.title.trim().length > 0 && 
          <div className={classes.contentItemTitle}>{item.title}</div>
        }

        { item.subtitle && item.subtitle.trim().length > 0 && 
          <div className={classes.contentItemSubTitle}>{item.subtitle}</div>
        }

        { item.description && item.description.trim().length > 0 && 
          <div className={classes.contentItemDescription} dangerouslySetInnerHTML={{ __html: item.description}}></div>
        }

        <Box className={classes.contentItemImgBox}>
          { item.imageUrl && item.imageUrl.trim().length > 0 &&
            <img src={item.imageUrl} className={classes.contentItemImg} alt={item.title}/>
          }

          { item.imageSubtitle && item.imageSubtitle.trim().length > 0 &&
            <div className={classes.contentItemImgSubtitle}>{item.imageSubtitle}</div>
          }
         
          {
            item.url && item.url.trim().length > 0 &&
              <a href={item.url} target="_blank" rel="noopener noreferrer">
                <Button className={classes.buttonSeeHere}>VEJA AQUI</Button>
              </a>
          }

        </Box>
      </Box>
    );
  }
  
  return (
      loading ? 
        <Loading width="50%"/> : 
        <Box>
          <Box className={classes.background} style={{ backgroundImage: `url(${currentContent.bannerUrl})`}}/>
          <Box className={classes.boxInfo}>
            <Box className={classes.boxInfoContent}>
              <Grid 
                container
                className={classes.boxInfoGrid}
              >
                <Grid 
                  item
                  xs={12}
                  sm={hasFeatured ? 6 : 12}
                  className={classes.boxInfoText}
                >
                  <div className={classes.breadcrumb}>
                  <span className={classes.breadcrumbItem}>INSTITUIÇÕES</span> / <span className={classes.breadcrumbItem}>{ currentInstituition.name }</span></div>
                  <div className={classes.contentTitle}>{ currentContent.title }</div>
                  <div className={classes.buttons}>
                    <a href={currentContent.scheduleUrl} className={classes.buttonOptionLink} target="_blank" rel="noopener noreferrer"><Button className={classes.buttonOption}>SAIBA MAIS</Button></a>
                    
                    <Button aria-controls="share-menu" aria-haspopup="true" onClick={handleClickShareMenu} className={classes.buttonOption}>COMPARTILHAR</Button>
                    <ShareMenu />
                    {/* <a href={currentContent.protocolsUrl} className={classes.buttonOptionLink} target="_blank" rel="noopener noreferrer"><Button className={classes.buttonOption}>PROTOCOLOS</Button></a> */}
                  
                  </div>
                  <span dangerouslySetInnerHTML={{ __html: currentContent.description}}></span>
                </Grid>
                { hasFeatured &&
                  <Grid 
                    item
                    xs={12}
                    sm={6}
                  >
                    <div className={classes.boxInfoData}>
                      <div className={classes.boxInfoDataContent}>
                        <span dangerouslySetInnerHTML={{ __html: currentContent.featured}}></span>
                      </div>
                    </div>
                  </Grid>
                }
              </Grid>

              { currentContent.sessions.map(renderItem) }

            </Box>
          </Box>
        </Box>
  );
}