import { makeStyles } from '@material-ui/core/styles';

const Style = makeStyles((theme) => {

    return ({
        bannerItemBox: {
            width: '100%',
            height: 232,
            overflow: 'hidden',
            backgroundSize: 'cover',
            cursor: 'pointer',
            marginBottom: 6,
            [theme.breakpoints.up("sm")]: {
                backgroundSize: '0 0',
                height: 260,
            },
            '&:hover': {
                "& $bannerItemBoxArrow": {
                    opacity: 1,
                },
                "& $bannerInfoBoxText": {
                    opacity: 1,
                },
                "& $bannerInfoBoxTitle": {
                    opacity: 1,
                },
            }
        },
        bannerItemBoxPrimary: {
            backgroundColor: `rgba(${theme.colors.primary})`
        },
        bannerItemBoxSecondary: {
            backgroundColor: `rgba(${theme.colors.secondary})`
        },
        bannerItemBoxTertiary: {
            backgroundColor: `rgba(${theme.colors.tertiary})`
        },
        bannerItemBoxContent: {
            width: '100%',
            maxWidth: theme.sizes.pageWidth,
            position: 'relative',
            height: '100%',
            marginLeft: 'auto',
            marginRight: 'auto'
        },
        bannerInfiniteImage: {
            width: '100%',
            height: 232,
            position: 'absolute',
            top: 0,
            backgroundRepeat: 'no-repeat',
            backgroundSize: '0 0',
            [theme.breakpoints.up("sm")]: {
                backgroundSize: 'cover',
                height: 260,
            },
            marginLeft: 375,
        },
        bannerInfiniteImageRight: {
            marginLeft: 'auto',
            right: 0,
            marginRight: 375
        },
        bannerInfoBox: {
            width: '70%',
            height: '100%',
            [theme.breakpoints.up("sm")]: {
                width: 375
            }
        },
        bannerInfoBoxRight: {
            marginRight: 0,
            marginLeft: 'auto'
        },
        bannerInfoBoxPrimary: {
            backgroundColor: `rgba(${theme.colors.primary}, 0.7)`,
            [theme.breakpoints.up("sm")]: {
                backgroundColor: `rgba(${theme.colors.primary}, 1)`,
            }
        },
        bannerInfoBoxSecondary: {
            backgroundColor: `rgba(${theme.colors.secondary}, 0.7)`,
            [theme.breakpoints.up("sm")]: {
                backgroundColor: `rgba(${theme.colors.secondary}, 1)`,
            }
        },
        bannerInfoBoxTertiary: {
            backgroundColor: `rgba(${theme.colors.tertiary}, 0.7)`,
            [theme.breakpoints.up("sm")]: {
                backgroundColor: `rgba(${theme.colors.tertiary}, 1)`,
            }
        },
        bannerInfoBoxText: {
            fontFamily: theme.fonts.primary.family,
            fontWeight: theme.fonts.primary.weight,
            color: `white`,
            fontSize: 19,
            opacity: 0.6,
            [theme.breakpoints.up("sm")]: {
                fontSize: 32,
                height: 186
            },
            letterSpacing: '-0.3px',
            paddingRight: 24,
            height: 150,
            paddingTop: 24,
            paddingLeft: 24
        },
        bannerInfoBoxTitle: {
            fontSize: 18,
            color: 'rgba(255, 255, 255, 1)',
            marginBottom: 5,
            [theme.breakpoints.up("sm")]: {
                marginBottom: 12,
            },
        },
        bannerItemBoxArrow: {
            fontSize: 32, 
            color: '#fff', 
            opacity: 0.6,
            paddingLeft: 24,
            marginLeft: -3,
        }
      
    })
});

export default Style;