import { Box } from '@material-ui/core';
import Style from './loading-style';
import LinearProgress from '@material-ui/core/LinearProgress';

export default function Loading({ width, style, className }) {

    const classes = Style();

    const currentWidth = width || "100%";

    return (
        <Box className={[classes.paulistaLoading, className]} style={{ width: currentWidth, ...style }}>
            <LinearProgress classes={{colorPrimary: classes.colorPrimary, barColorPrimary: classes.barColorPrimary}}/>
        </Box>
    )
}