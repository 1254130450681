import { makeStyles } from '@material-ui/core/styles';

const Style = makeStyles((theme) => ({
    headerContainer: {
        zIndex: 100,
        backgroundColor: 'white',
        width: '100%',
        height: 60,
        left:0,
        right: 0,
        position:'fixed',
        [theme.breakpoints.up("sm")]: {
            height: 105
        },
        transition: 'all 0.1s linear',

    },
    headerRoot: {
        width: '100%',
        maxWidth: theme.sizes.pageWidth,
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    loading: {
        maxWidth: theme.sizes.pageWidth - 2*theme.sizes.generalPadding,
        marginTop: 0

    },
    toolbar: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        paddingLeft: theme.sizes.generalPadding,
    },
    logoBox: {
        flexGrow: 1,
        flexDirection: "column",
        justifyContent: "center",
        height: '60px',
        lineHeight: '60px',
        [theme.breakpoints.up("sm")]: {
            height: "105px",
            lineHeight: '105px',
        }
    },
    logoText: {
        fontSize: 24,
        fontFamily: theme.fonts.primary.family,
        fontWeight: theme.fonts.primary.weight,
        letterSpacing: -1,
        transform: 'scale(1, 1.1)',
        [theme.breakpoints.up("sm")]: {
            fontSize: 38,
        },
        cursor: 'pointer',
        display: 'inline-block',
        height: '100%'
    },
    menu: {
        display: 'flex',
        flexDirection: 'row',
        padding: 0,
        justifyContent: 'center',
        alignItems: 'center',
        paddingRight: theme.sizes.generalPadding,
    },
    menuIcon: {
        fontSize: '2.4rem',
        color: `rgba(${theme.colors.secondary})`,
    },
    menuItem: {
        fontFamily: theme.fonts.primary.family,
        marginLeft: 30,
        fontSize: 18,
        fontWeight: 700,
        cursor: 'pointer',
        MozUserSelect: "none",
        WebkitUserSelect: "none",
        msUserSelect: "none",
        opacity: 0.7,
        marginTop: -3,
        '&:hover': {
            borderBottom: `3px solid rgba(${theme.colors.primary})`,
            opacity: 1,
            marginTop: 0,
        }
    },
    menuItemActive: {
        borderBottom: `3px solid rgba(${theme.colors.primary})`,
        marginTop: 0,
    },
    menuItemSearch: {
        marginLeft: 32,
        fontSize: 28,
        color: '#000',
        opacity: 0.7,
        cursor: 'pointer',
        '&:hover': {
            opacity: 1,
        }
    },
    menuItemCloseSearch: {
        marginLeft: 24,
        fontSize: 36,
        color: '#000',
        opacity: 0.7,
        cursor: 'pointer',
        '&:hover': {
            opacity: 1,
        }
    }
}));

export default Style;