import { Box, Hidden } from '@material-ui/core';
import Style from './visit-map-style';
import avenue from '../../../../assets/images/avenida-paulista-dark.png';
import avenueLight from '../../../../assets/images/avenida-paulista-light.png';
import avenueMobile from '../../../../assets/images/avenida-paulista-dark-mobile.png';
import { useState } from 'react';
import { getWebData } from "../../../../services/api.service";

export default function VisitMap() {

    const institutions = getWebData().institutions;
    const classes = Style();
    const [pcMap, setPcMap] = useState(avenue);

    const turnOffMap = () => {
        setPcMap(avenue);
    }

    const turnOnMap = (e) => {
        setPcMap(avenueLight);
    }
    
    return (            
        <Box className={classes.box}>
            <div className={classes.boxContent}>
                <div className={classes.title}>Instituições</div>
                <div className={classes.boxMap}>                
                    <Hidden only={['xs']}>
                        <div className={classes.localBoxTop} >
                            <div className={[classes.localText, classes.localTextOne].join(' ')}>
                                <span onMouseOver={turnOnMap} onMouseLeave={turnOffMap} className={classes.localTextString}>
                                    <a href={institutions[0].url} target="_BLANK" rel="noopener noreferrer">
                                        <span className={classes.localNumber}>{institutions[0].streetNumber}</span> <span className={classes.localName}>{institutions[0].mapName}</span>
                                    </a>
                                </span>
                            </div>
                            <div className={[classes.localText, classes.localTextTwo].join(' ')}>
                                <span onMouseOver={turnOnMap} onMouseLeave={turnOffMap} className={classes.localTextString}>
                                    <a href={institutions[1].url} target="_BLANK" rel="noopener noreferrer">
                                        <span className={classes.localNumber}>{institutions[1].streetNumber}</span> <span className={classes.localName}>{institutions[1].mapName}</span>
                                    </a>
                                </span>
                            </div>
                            <div className={[classes.localText, classes.localTextThree].join(' ')}>
                                <span onMouseOver={turnOnMap} onMouseLeave={turnOffMap} className={classes.localTextString}>
                                    <a href={institutions[2].url} target="_BLANK" rel="noopener noreferrer">
                                        <span className={classes.localNumber}>{institutions[2].streetNumber}</span> <span className={classes.localName}>{institutions[2].mapName}</span>
                                    </a>
                                </span>
                            </div>
                        </div>

                        <img src={pcMap} className={classes.avenueImg } alt="Avenida Paulista"/>

                        <div className={classes.localBoxBottom}>
                            <div className={[classes.localText, classes.localTextFour].join(' ')}>
                                <span onMouseOver={turnOnMap} onMouseLeave={turnOffMap} className={classes.localTextString}>
                                    <a href={institutions[3].url} target="_BLANK" rel="noopener noreferrer">
                                        <span className={classes.localNumber}>{institutions[3].streetNumber}</span> <span className={classes.localName}>{institutions[3].mapName}</span>
                                    </a>
                                </span>
                            </div>
                            <div className={[classes.localText, classes.localTextFive].join(' ')}>
                                <span onMouseOver={turnOnMap} onMouseLeave={turnOffMap} className={classes.localTextString}>
                                    <a href={institutions[4].url} target="_BLANK" rel="noopener noreferrer">
                                        <span className={classes.localNumber}>{institutions[4].streetNumber}</span> <span className={classes.localName}>{institutions[4].mapName}</span>
                                    </a>
                                </span>
                            </div>
                            <div className={[classes.localText, classes.localTextSix].join(' ')}>
                                <span onMouseOver={turnOnMap} onMouseLeave={turnOffMap} className={classes.localTextString}>
                                    <a href={institutions[5].url} target="_BLANK" rel="noopener noreferrer">
                                        <span className={classes.localNumber}>{institutions[5].streetNumber}</span> <span className={classes.localName}>{institutions[5].mapName}</span>
                                    </a>
                                </span>
                            </div>
                            <div className={[classes.localText, classes.localTextSeven].join(' ')}>
                                <span onMouseOver={turnOnMap} onMouseLeave={turnOffMap} className={classes.localTextString}>
                                    <a href={institutions[6].url} target="_BLANK" rel="noopener noreferrer">
                                        <span className={classes.localNumber}>{institutions[6].streetNumber}</span> <span className={classes.localName}>{institutions[6].mapName}</span>
                                    </a>
                                </span>
                            </div>
                        </div>
                    </Hidden>
                    <Hidden smUp>
                        <div className={classes.boxMapMobile}>
                            <div className={classes.localBoxMobileLeft}>
                                <div className={[classes.localText, classes.localTextFour].join(' ')}>
                                    <a href={institutions[3].url} target="_BLANK" rel="noopener noreferrer">
                                        <span className={classes.localNumber}>{institutions[3].streetNumber}</span> <span className={classes.localName}>{institutions[3].mapName}</span>
                                    </a>
                                </div>
                                <div className={[classes.localText, classes.localTextFive].join(' ')}>
                                    <a href={institutions[4].url} target="_BLANK" rel="noopener noreferrer">
                                        <span className={classes.localNumber}>{institutions[4].streetNumber}</span> <span className={classes.localName}>{institutions[4].mapName}</span>
                                    </a>
                                </div>
                                <div className={[classes.localText, classes.localTextSix].join(' ')}>
                                    <a href={institutions[5].url} target="_BLANK" rel="noopener noreferrer">
                                        <span className={classes.localNumber}>{institutions[5].streetNumber}</span> <span className={classes.localName}>{institutions[5].mapName}</span>
                                    </a>
                                </div>
                                <div className={[classes.localText, classes.localTextSeven].join(' ')}>
                                    <a href={institutions[6].url} target="_BLANK" rel="noopener noreferrer">
                                        <span className={classes.localNumber}>{institutions[6].streetNumber}</span> <span className={classes.localName}>{institutions[6].mapName}</span>
                                    </a>
                                </div>
                            </div>
                            <img src={avenueMobile} className={classes.avenueImgMobile } alt="Avenida Paulista"/>
                            <div className={classes.localBoxMobileRight}>
                                <div className={classes.localBoxMobileRightContent}>
                                    <div className={[classes.localText, classes.localTextOne].join(' ')}>
                                        <a href={institutions[0].url} target="_BLANK" rel="noopener noreferrer">
                                            <span className={classes.localNumber}>{institutions[0].streetNumber}</span> <span className={classes.localName}>{institutions[0].mapName}</span>
                                        </a>
                                    </div>
                                    <div className={[classes.localText, classes.localTextTwo].join(' ')}>
                                        <a href={institutions[1].url} target="_BLANK" rel="noopener noreferrer">
                                            <span className={classes.localNumber}>{institutions[1].streetNumber}</span> <span className={classes.localName}>{institutions[1].mapName}</span>
                                        </a>
                                    </div>
                                    <div className={[classes.localText, classes.localTextThree].join(' ')}>
                                        <a href={institutions[2].url} target="_BLANK" rel="noopener noreferrer">
                                            <span className={classes.localNumber}>{institutions[2].streetNumber}</span> <span className={classes.localName}>{institutions[2].mapName}</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Hidden>
                </div>
            </div>
        </Box>
    );
}