import { makeStyles } from '@material-ui/core/styles';

const Style = makeStyles((theme) => {

    return ({
        background: {
            width: '100%',
            height: '16%',
            textAlign: 'center',
            [theme.breakpoints.up("sm")]: {
                height: '28%',
                marginLeft: 0,
                marginRight: 0
            },
        },
        backgroundContent: {
            position: 'relative',
            maxWidth: 'calc(100% - '+(2*theme.sizes.generalPadding)+'px)',
            marginLeft: 'auto',
            marginRight: 'auto',
            height: '100%',
            width: '100%',
            textAlign: 'left',
            [theme.breakpoints.up("sm")]: {
                maxWidth: '100%'
            },
        },
        pageTitle: {
            width: '100%',
            marginLeft: theme.sizes.generalPadding,
            fontFamily: theme.fonts.primary.family,
            fontWeight: theme.fonts.primary.weight,
            color: 'white',
            zIndex: 2,
            position: 'absolute',
            fontSize: 40,
            bottom: 20,
            [theme.breakpoints.up("sm")]: {
                fontSize: 80,
                bottom: 40,
            },
        },
        boxInfo:{
            width: '100%',
            maxWidth: theme.sizes.pageWidth,
            marginLeft: 'auto',
            marginRight: 'auto',
            paddingTop: 40,
            fontFamily: theme.fonts.primary.family,
            fontWeight: 100,
            marginBottom: 40
        },
        boxInfoContent: {
            width: `calc(100% - ${2*theme.sizes.generalPadding}px)`,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
        boxInfoGrid: {
            marginBottom: 40
        },
        boxInfoText: {
            fontSize: 22,
            lineHeight: '32px',
            paddingRight: 0,
            fontWeight: 500,
            [theme.breakpoints.up("sm")]: {
                paddingRight: 32,
            },
        },
        instituitionItems: {
            marginTop: 30
        },
        instituitionItem: {
            marginBottom: 20
        },
        instituitionItemTitle: {
            marginBottom: 5,
            fontWeight: theme.fonts.primary.weight,
            textTransform: 'uppercase'
        },
        boxMap: {
            backgroundColor: `rgba(${theme.colors.tertiary}, 1)`,
            position: 'relative',
            marginTop: 30,
            [theme.breakpoints.up("sm")]: {
                marginTop: 0,
            },
            height: 580,
            display: 'flex',
            justifyContent: 'center',
            paddingTop: 16,
            paddingBottom: 16,
            alignItems: 'center'
        },
        boxMapLeft: {
            width: 110,
            height: 500,
            textAlign: 'right',
            display: 'flex',
            justifyContent: 'flex-end',
            flexDirection: 'column',
            marginRight: 5,
            marginLeft: 10,
            paddingRight: 10,
            [theme.breakpoints.up("sm")]: {
                fontSize: 0,
            },
        },
        boxMapRight: {
            width: 110,
            height: 500,
            textAlign: 'left',
            marginLeft: 10,
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
            flexWrap: 'wrap',
        },
        boxMapImg: {
            height: 500,
        },
        boxMapLeftText: {
            fontWeight: theme.fonts.primary.weight,
            color: 'white',
            width: 90,
            marginTop: 35,
            opacity: 0.7,
            '&:hover': {
                opacity: 1,
            },
            fontSize: 20,
            [theme.breakpoints.up("sm")]: {
                fontSize: 24,
                width: 110,
            },
        },
        boxMapRightText: {
            fontWeight: theme.fonts.primary.weight,
            color: 'white',
            width: 70,
            opacity: 0.7,
            '&:hover': {
                opacity: 1,
            },
            fontSize: 20,
            [theme.breakpoints.up("sm")]: {
                fontSize: 24,
                width: 110,
            },
        },
        boxMapLogo: {
            fontFamily: theme.fonts.primary.family,
            fontWeight: theme.fonts.primary.weight,
            color: 'white',
            position: 'absolute',
            top: 110,
            left: -70,
            fontSize: 24,
            transform: 'rotate(-90deg)',

        }
    })
});

export default Style;