import { Box } from '@material-ui/core';
import BannerItem from './components/banner-item';
import { getWebData, getInstitutionById } from "../../../../services/api.service";
import { partition } from "../../../../utils/partition";
import { sortDesc } from "../../../../utils/sortArray";


export default function Banner() {

    const posts = getWebData().posts.filter(post => post.main);
    const [postsRandom, postsToSort] = partition(posts, p => p.show_sort === 0);

    sortDesc(postsToSort, "show_sort");

    const renderBannerItem = (item, index) => {
        const colorType = index === 1 || index%4 === 0 || index%5 === 0 ? "primary" : index === 2 || index%6 === 0 ? "secondary" : "tertiary";
        const instituition = getInstitutionById(item.institutionId); 
        
        return (
            <BannerItem
                colorType={colorType}
                instituition={instituition.name}
                title={item.title}
                img={item.bannerUrl}
                slug={item.slug}
                key={item.slug}
                instituitionCode={item.id}
                isRight={(index-1)%2 !== 0}
            />
        );
    }

    return (
        <Box>
           { postsToSort.concat(postsRandom.sort((a, b) => 0.5 - Math.random())).map((item, i) => renderBannerItem(item, i+1)) }
        </Box>
    );
}
