import { makeStyles } from '@material-ui/core/styles';

const Style = makeStyles((theme) => {
    return ({
        boxBackground: {
            height: '100vh',
            width: '100%',
            top: 0,
            right: 0,
            left: 0,
            backgroundColor: 'rgba(0,0,0,0.3)',
        },
        box: {
            height: 350,
            width: '100%',
            top: 0,
            right: 0,
            left: 0,
            backgroundColor: 'white',
            boxShadow: '0 4px 10px -5px rgba(0,0,0,0.2)'
        },
        boxContent: {
            width: '100%',
            maxWidth: theme.sizes.pageWidth,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
        boxLimit:{
            width: `calc(100% - ${2*theme.sizes.generalPadding}px)`,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
        inputSearch: {
            width: '100%',
            height: 80,
            backgroundColor: '#F7F7F9',
            border: 0,
            fontSize: 30,
            color: '#000',
            paddingLeft: '30px',
            outline: 'none',
            marginTop: 100,
        },
        buttonSearch: {
            backgroundColor: 'white',
            borderRadius: 2,
            padding: 10,
            paddingLeft: 20,
            paddingRight: 20,
            fontFamily: theme.fonts.primary.family,
            fontWeight: theme.fonts.primary.weight,
            fontSize: 21,
            color: `rgba(${theme.colors.primary}, 1)`,
            borderBottomRightRadius: 0,
            borderBottomLeftRadius: 0,
            borderBottom: `3px solid rgba(${theme.colors.primary}, 1)`,
            opacity: 0.9,
            '&:hover': {
                opacity: 1,
                backgroundColor: 'white',
            },
        },
        buttonSearchBox: {
            textAlign: 'center',
            paddingTop: 20
        }
        
        
    })
});


export default Style;