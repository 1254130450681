import { makeStyles } from '@material-ui/core/styles';

const Style = makeStyles((theme) => ({
    mobileMenuBox: {
        width: '100vw',
        height: '100vh',
        backgroundColor: '#fff',
        position: 'fixed',
        zIndex: 1000,
        top: 60,
        bottom: 0,
        left: 0,
        right: 0,
        paddingTop: 20
    },
    mobileMenuBoxContent: {
        width: '100%',
        height: 600, 
        overflow: 'scroll'
    },
    mobileMenuItem: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: 24,
        marginBottom: 25,
    },
    mobileMenuDot: {
        width: 20,
        height: 20,
        borderRadius: '50%',
        marginRight: 10
    },
    mobileMenuDotOn: {
        backgroundColor: '#72D73A',
    },
    mobileMenuDotOff: {
        backgroundColor: 'rgba(240,65,50,0.99)',
    },
    mobileMenuText: {
        fontSize: 28,
        fontFamily: theme.fonts.primary.family,
        fontWeight: theme.fonts.primary.weight,
        opacity: 0.8
    },
    mobileMenuTextLink: {
        fontSize: 32,
        fontFamily: theme.fonts.primary.family,
        fontWeight: theme.fonts.primary.weight,
        opacity: 0.6
    }
}));

export default Style;