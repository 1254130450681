import React from 'react';
import { Box, Grid } from '@material-ui/core';
import Style from './about-style';
import avenueMobile from '../../assets/images/avenida-paulista-light-mobile.png';
import { getWebData } from "../../services/api.service";
import { Link } from "react-router-dom";
import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/styles.css';
import sliderStyles from './slider-styles.css';

const AutoplaySlider = withAutoplay(AwesomeSlider);

export default function About() {

  const data = getWebData();
  const institutions = data.institutions;
  const classes = Style();

  return (
    <Box>
      
      <Box className={classes.background}>
        <Box className={classes.backgroundContent}>
          <AutoplaySlider
            play={true}
            cancelOnInteraction={false} // should stop playing on user interaction
            interval={6000}
            bullets={false}
            cssModule={sliderStyles}
          >
            { data.about.images.map(image => <div data-src={image.url} style={{ opacity: 0.8 }} />) }
          </AutoplaySlider>
        </Box>
      </Box>

      <Box className={classes.boxInfo}>

        <Box className={classes.boxInfoContent}>
          <Grid 
            container
            className={classes.boxInfoGrid}
          >
            <Grid 
              item
              xs={12}
              sm={7}
              className={classes.boxInfoText}
            >
              <div dangerouslySetInnerHTML={{ __html: data.about.description  }}></div>
            </Grid>
            <Grid 
              item
              xs={12}
              sm={5}
            >
              <div className={classes.boxMap}>
                <div className={classes.boxMapLogo}>PAULISTA CULTURAL</div>
                <div className={classes.boxMapLeft}>
                  <div className={classes.boxMapLeftText}>
                      <Link to={"/instituition/" + institutions[3].slug }>  
                          { institutions[3].mapName}
                      </Link>
                  </div>
                  <div className={classes.boxMapLeftText}>
                      <Link to={"/instituition/" + institutions[4].slug }>  
                          { institutions[4].mapName}
                      </Link>
                  </div>
                  <div className={classes.boxMapLeftText}>
                      <Link to={"/instituition/" + institutions[5].slug }>
                          { institutions[5].mapName}
                      </Link>
                  </div>
                  <div className={classes.boxMapLeftText}>
                      <Link to={"/instituition/" + institutions[6].slug }>
                          { institutions[6].mapName}
                      </Link>
                  </div>
                </div>

                <img src={avenueMobile} className={classes.boxMapImg } alt="Avenida Paulista"/>

                <div className={classes.boxMapRight}>
                  <div className={classes.boxMapRightText}>
                      <Link to={"/instituition/" + institutions[0].slug }>
                          { institutions[0].mapName}
                      </Link>
                  </div>
                  <div className={classes.boxMapRightText}>
                      <Link to={"/instituition/" + institutions[1].slug}>
                          { institutions[1].mapName}
                      </Link>
                  </div>
                  <div className={classes.boxMapRightText}>
                      <Link to={"/instituition/" + institutions[2].slug }>
                          { institutions[2].mapName}
                      </Link>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>          
        </Box>
      </Box>
    </Box>
  );
}