import { getWebData } from "./api.service";
import moment from 'moment';

const config = {};

const checkIsOpen = (id) => {
    const date = moment().format('DDMMYYYY');
    const today =  moment();
    const now = parseInt(today.format('Hmm'));

    if(config[id] && config[id].custom && config[id].custom[date]){
        for (let fromTo of config[id].custom[date]) {
            if(now >= fromTo.from && now <= fromTo.to) return true;
        }
        return false;
    } else {
        if(config[id] && config[id].weekday){
            const fromTo = config[id].weekday[(today.day()).toString()];
            return now >= fromTo.from && now <= fromTo.to;
        }
    }    
};

export const institutionIsOpenInDate = (id, date) => {
    const formattedDate = date.format("DDMMYYYY");
    if(config[id] && config[id].custom && config[id].custom[formattedDate]){
        for (let fromTo of config[id].custom[formattedDate]) {
            if(!(fromTo.from === 2359 && fromTo.to === 0)) return true;
        }
        return false;
    } else {
        if(config[id] && config[id].weekday){
            const fromTo = config[id].weekday[(date.day()).toString()];
            return !(fromTo.from === 2359 && fromTo.to === 0);
        }
    }  
};


export const institutionIsOpen = (id) => {
    if(id) return checkIsOpen(id) ? true : false;
      
    return getWebData().institutions.reduce(function(result, institution) {
        result[institution.id] = checkIsOpen(institution.id);
        return result;
    }, {});
};

export const loadTimesConfig = () => {
  try {
    getWebData().institutions?.forEach(institution => {
        institution?.times.forEach(time => {
            if(time.weekdays){
                if(!config[institution.id]) config[institution.id] = {};
                config[institution.id].weekday = {};
                time.weekdays.split(";").forEach((data, i) => {
                    if(data !== "false") {
                        const fromTo = data.split("-");
                        config[institution.id].weekday[i.toString()] = {
                            from: parseInt(fromTo[0].replace(":","")),
                            to: parseInt(fromTo[1].replace(":",""))
                        }
                    } else {
                        config[institution.id].weekday[i.toString()] = {
                            from: 2359,
                            to: 0
                        }
                    }
                });
            } else {
                const date = moment(new Date(time.customDay)).format('DDMMYYYY');
                if(config[institution.id] && config[institution.id].custom && config[institution.id].custom[date]){
                    config[institution.id].custom[date].push({
                        from: time.customOpen ? parseInt(time.customFrom?.replace(":","")) : 2359,
                        to: time.customOpen ? parseInt(time.customTo?.replace(":","")) : 0
                    });
                } else {
                    if(!config[institution.id]) config[institution.id] = {};
                    if(!config[institution.id].custom) config[institution.id].custom = {};
                   
                    config[institution.id].custom[date] = [
                        {
                            from: time.customOpen ? parseInt(time.customFrom?.replace(":","")) : 2359,
                            to: time.customOpen ? parseInt(time.customTo?.replace(":","")) : 0
                        }
                    ];
                }
            }
        });
    });
  } catch(e) {
    console.log("Error when loadTimesConfiga. ", e)
  }
}