import { makeStyles } from '@material-ui/core/styles';

const Style = makeStyles((theme) => {

    return ({
        boxInfo:{
            width: '100%',
            maxWidth: theme.sizes.pageWidth,
            marginLeft: 'auto',
            marginRight: 'auto',
            paddingTop: 40,
            fontFamily: theme.fonts.primary.family,
            fontWeight: 100,
            marginBottom: 40
        },
        boxInfoContent: {
            width: `calc(100% - ${2*theme.sizes.generalPadding}px)`,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
        itemsTitle: {
            fontFamily: theme.fonts.primary.family,
            fontWeight: theme.fonts.primary.weight,
            fontSize: 32,
            paddingTop: 20,
        },
        itemsTitleResult: {
            fontWeight: 500,
        },
        itemsTitleNext: {
            fontFamily: theme.fonts.primary.family,
            fontWeight: theme.fonts.primary.weight,
            fontSize: 32,
            paddingTop: 50,
        },
        itemsTitleToday: {
            fontFamily: theme.fonts.primary.family,
            fontWeight: theme.fonts.primary.weight,
            fontSize: 32,
            paddingTop: 50,
            marginBottom: 30
        },
        itemsTitleLine: {
            width: '100%',
            backgroundColor: '#c8c8c8',
            border: 0,
            height: 1,
            marginTop: 30,
        },
        item: {
            marginTop: 25,
            opacity: 0.8,
            cursor: 'pointer',
            '&:hover': {
                opacity: 1,
            },
        },
        itemContent: {
            paddingTop: 15,
            paddingBottom: 15,
            borderBottom: "1px solid #000",
            [theme.breakpoints.up("sm")]: {
                paddingLeft: 35,
            },
        },
        postType: {
            fontFamily: theme.fonts.primary.family,
            fontWeight: theme.fonts.primary.weight,
            fontSize: 20,
            color: `rgba(${theme.colors.primary}, 1)`,
        },
        postTitle: {
            fontFamily: theme.fonts.primary.family,
            fontWeight: theme.fonts.primary.weight,
            fontSize: 30,
            paddingTop: 5,
            paddingBottom: 15,
            [theme.breakpoints.up("sm")]: {
                paddingTop: 15,
                paddingBottom: 25,
            },
            color: `rgba(${theme.colors.primary}, 1)`,
        },
        postTag: {
            fontFamily: theme.fonts.primary.family,
            fontWeight: 500,
            fontSize: 14,
            color: 'white',
            backgroundColor: `rgba(${theme.colors.secondary}, 1)`,
            padding: 5,
            textAlign: 'center',
            verticalAlign: 'center',
            borderRadius: 3,
            paddingTop: 8,
            marginRight: 8
        },
        seeMoreButton: {
            width: '100%',
            marginTop: 80,
            border: 0,
            backgroundColor: 'transparent',
            borderBottom: `3px solid rgba(${theme.colors.primary}, 1)`,
            paddingBottom: 15,
            fontFamily: theme.fonts.primary.family,
            fontWeight: theme.fonts.primary.weight,
            fontSize: 25,
            color: `rgba(${theme.colors.primary}, 1)`,
            outline: 'none',
            cursor: 'pointer',
            opacity: 0.6,
            '&:hover': {
                opacity: 1,
            },
            marginBottom: 60
        }
        
    })
});

export default Style;