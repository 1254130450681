import { Box, Hidden } from '@material-ui/core';
import Style from './instituition-map-style';
import avenue from '../../../../assets/images/avenida-paulista-dark.png';
import avenueLight from '../../../../assets/images/avenida-paulista-light-top.png';
import { useState } from 'react';
import { Link } from "react-router-dom";
import { getWebData } from "../../../../services/api.service";

export default function InstituitionMap({ open, closeMap }) {

    const institutions = getWebData().institutions;

    const classes = Style();
    const [pcMap, setPcMap] = useState(avenue);

    const turnOffMap = () => {
        setPcMap(avenue);
    }

    const turnOnMap = (e) => {
        setPcMap(avenueLight);
    }
    
    return (    
        open &&    
            <Hidden only={['xs']}>    
                <Box className={classes.box} >
                    <div className={classes.boxContent}>
                        <div className={classes.boxMap}>                
                            <div className={classes.localBoxTop} >
                                <div className={[classes.localText, classes.localTextOne].join(' ')}>
                                    <span onMouseOver={turnOnMap} onMouseLeave={turnOffMap} onClick={closeMap} className={classes.localTextString}>
                                        <Link to={"/instituition/" + institutions[0].slug}>   
                                            <span className={classes.localNumber}>{institutions[0].streetNumber}</span> <span className={classes.localName}>{institutions[0].mapName}</span>
                                        </Link> 
                                    </span>
                                </div>
                                <div className={[classes.localText, classes.localTextTwo].join(' ')}>
                                    <span onMouseOver={turnOnMap} onMouseLeave={turnOffMap} onClick={closeMap} className={classes.localTextString}>
                                        <Link to={"/instituition/" + institutions[1].slug }>   
                                            <span className={classes.localNumber}>{institutions[1].streetNumber}</span> <span className={classes.localName}>{institutions[1].mapName}</span>
                                        </Link>
                                    </span>
                                </div>
                                <div className={[classes.localText, classes.localTextThree].join(' ')}>
                                    <span onMouseOver={turnOnMap} onMouseLeave={turnOffMap} onClick={closeMap} className={classes.localTextString}>
                                        <Link to={"/instituition/" + institutions[2].slug }>   
                                            <span className={classes.localNumber}>{institutions[2].streetNumber}</span> <span className={classes.localName}>{institutions[2].mapName}</span>
                                        </Link>
                                    </span>
                                </div>
                            </div>

                            <img src={pcMap} className={classes.avenueImg } alt="Avenida Paulista"/>

                            <div className={classes.localBoxBottom}>
                                <div className={[classes.localText, classes.localTextFour].join(' ')}>
                                    <span onMouseOver={turnOnMap} onMouseLeave={turnOffMap} onClick={closeMap}  className={classes.localTextString}>
                                        <Link to={"/instituition/" + institutions[3].slug }>   
                                            <span className={classes.localNumber}>{institutions[3].streetNumber}</span> <span className={classes.localName}>{institutions[3].mapName}</span>
                                        </Link>
                                    </span>
                                </div>
                                <div className={[classes.localText, classes.localTextFive].join(' ')}>
                                    <span onMouseOver={turnOnMap} onMouseLeave={turnOffMap} onClick={closeMap} className={classes.localTextString}>
                                        <Link to={"/instituition/" + institutions[4].slug }>   
                                            <span className={classes.localNumber}>{institutions[4].streetNumber}</span> <span className={classes.localName}>{institutions[4].mapName}</span>
                                        </Link>
                                    </span>
                                </div>
                                <div className={[classes.localText, classes.localTextSix].join(' ')}>
                                    <span onMouseOver={turnOnMap} onMouseLeave={turnOffMap} onClick={closeMap}  className={classes.localTextString}>
                                        <Link to={"/instituition/" + institutions[5].slug }>   
                                            <span className={classes.localNumber}>{institutions[5].streetNumber}</span> <span className={classes.localName}>{institutions[5].mapName}</span>
                                        </Link>
                                    </span>
                                </div>
                                <div className={[classes.localText, classes.localTextSeven].join(' ')}>
                                    <span onMouseOver={turnOnMap} onMouseLeave={turnOffMap} onClick={closeMap}  className={classes.localTextString}>
                                        <Link to={"/instituition/" + institutions[6].slug }>   
                                            <span className={classes.localNumber}>{institutions[6].streetNumber}</span> <span className={classes.localName}>{institutions[6].mapName}</span>
                                        </Link>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </Box>
            </Hidden>
    );
}