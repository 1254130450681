import { Box } from '@material-ui/core';
import Style from './banner-item-style';
import { ArrowRightIcon } from '../../../../../../packages/icons';
import { Link } from "react-router-dom";

export default function BannerItem({ colorType, instituition, title, img, slug, instituitionCode, isRight }) {

    const classes = Style();
    const color = colorType.charAt(0).toUpperCase() + colorType.slice(1);
    const itemSide = isRight ? "Right" : "";

    return (
        <Box className={classes["bannerItemBox" + color]}>
            <Link to={`/content/${slug}`}>  
                <div className={classes.bannerItemBox}  style={{ backgroundImage: `url(${img})` }}>
                    <div className={classes.bannerItemBoxContent}>
                        <div 
                            className={[classes.bannerInfoBox, classes["bannerInfoBox" + itemSide], classes["bannerInfoBox" + color]].join(' ')}
                        >
                            <Box>
                                <div className={classes.bannerInfoBoxText}>
                                    <div className={classes.bannerInfoBoxTitle}>{ instituition }</div>
                                    { title }
                                </div>
                                <ArrowRightIcon className={classes.bannerItemBoxArrow}/>
                            </Box>
                        </div>
                        <Box 
                            className={[classes.bannerInfiniteImage, classes["bannerInfiniteImage" + itemSide]].join(' ')} 
                            style={{  backgroundImage: `url(${img})` }} 
                        />
                    </div>
                </div>
            </Link>
        </Box>
    );
}