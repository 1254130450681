import { SvgIcon } from '@material-ui/core';

export function SearchIcon(props) {
    return (
      <SvgIcon {...props} viewBox="0 0 56.966 56.966">
        <path d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23
	s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92
	c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17
	s-17-7.626-17-17S14.61,6,23.984,6z" />
      </SvgIcon>
    );
}

export function ArrowRightIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 64 64">
      <path d="m37.379 12.552c-.799-.761-2.066-.731-2.827.069-.762.8-.73 2.066.069 2.828l15.342 14.551h-39.963c-1.104 0-2 .896-2 2s.896 2 2 2h39.899l-15.278 14.552c-.8.762-.831 2.028-.069 2.828.393.412.92.62 1.448.62.496 0 .992-.183 1.379-.552l17.449-16.62c.756-.755 1.172-1.759 1.172-2.828s-.416-2.073-1.207-2.862z" />
    </SvgIcon>
  );
}

export function ArrowUpIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 512 512">
      <path d="M423.542,163.125l-160-160c-4.167-4.167-10.917-4.167-15.083,0l-160,160c-3.042,3.063-3.969,7.615-2.313,11.625
			c1.646,3.99,5.542,6.583,9.854,6.583h21.333c2.833,0,5.542-1.125,7.542-3.125L234.667,68.417v432.917
			c0,5.896,4.771,10.667,10.667,10.667h21.333c5.896,0,10.667-4.771,10.667-10.667V68.417l109.792,109.792
			c2,2,4.708,3.125,7.542,3.125H416c4.313,0,8.208-2.594,9.854-6.583c0.552-1.323,0.813-2.708,0.813-4.083
			C426.667,167.896,425.583,165.167,423.542,163.125z" />
    </SvgIcon>
  );
}

export function ArrowRightFilterIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 32 32">
      <g><path d="M21,23c-0.3,0-0.5-0.1-0.7-0.3c-0.4-0.4-0.4-1,0-1.4l5.3-5.3l-5.3-5.3c-0.4-0.4-0.4-1,0-1.4s1-0.4,1.4,0l6,6   c0.4,0.4,0.4,1,0,1.4l-6,6C21.5,22.9,21.3,23,21,23z"/></g><g><path d="M27,17H5c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S27.6,17,27,17z"/></g>
    </SvgIcon>
  );
}